import { Component, Input, OnInit } from '@angular/core';
import { AxisLabelContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-trainings-tab',
  templateUrl: './trainings-tab.component.html',
  styleUrls: ['./trainings-tab.component.scss']
})
export class TrainingsTabComponent implements OnInit {

  @Input("employeeId") employeeId;


  public banksData = [
    { name: "Communication Skills ", pre: 3, post: 1 },
    { name: "Business Writing", pre: 2, post: 2 },
    { name: "Time Management", pre: 2, post: 3 },
    { name: "Customer Service", pre: 1, post: 0 },
    { name: "Problem Solving", pre: 2, post: 2 },
  ];

  public labelContent(e: AxisLabelContentArgs): string {
    return `${e.dataItem.time.substring(0, 2)}h`;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
