import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomAccountService } from '@proxy/intelligence/accounts/custom-account.service';
import { ChangePasswordDto } from '@proxy/intelligence/accounts/models';
import { ConfigStateService, CurrentTenantDto } from '@abp/ng.core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  tenant: CurrentTenantDto;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private customAccountService: CustomAccountService, private router: Router, private configState: ConfigStateService) { }
  userId: any;
  token: any;
  ngOnInit(): void {

    this.tenant = this.configState.getOne("currentTenant");

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.userId = params.uid;
        this.token = params.t;
      }
      );

    this.buildForgetPasswordForm();
  }
  private buildForgetPasswordForm() {
    this.submitForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      tenant: [this.tenant.name ? this.tenant.name : '', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() { return this.submitForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.submitForm.invalid) {
      return;
    }

    var request: ChangePasswordDto = {
      userId: this.userId,
      token: this.token,
      newPassword: this.submitForm.controls["password"].value
    }
    this.customAccountService.changePasswordByInput(request).subscribe((res: any) => {
      console.log("resetPasswordByInput", res)
      this.submitted = false;
      this.router.navigate([''])
    }, (err: any) => {
      console.log("resetPasswordByInput err", err)

    })
  }

}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}