import { SessionStateService, SubscriptionService } from '@abp/ng.core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
    UserNavigationEntitiesService,
    UserNavigationEntityDto,
} from '@proxy/intelligence/navigation';
import { BehaviorSubject } from 'rxjs';
import { fadeUpDown } from '../shared/animations';

interface NavigationNode {
    name: string;
    icon: string;
    id: string;
    isLegalEntity: boolean;
    parentId: string;
    children: NavigationNode[];
}

@Component({
    selector: 'entity-navigation',
    templateUrl: './entity-navigation.component.html',
    styleUrls: ['./entity-navigation.component.scss'],
    providers: [SubscriptionService],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeUpDown]
})
export class EntityNavigationComponent implements OnInit {
    showTree: boolean = false;
    entities: UserNavigationEntityDto[] = [];
    open: boolean = false;
    filteredData$ = new BehaviorSubject<UserNavigationEntityDto[]>([]);

    selectedEntity = {} as UserNavigationEntityDto;

    @Output('onNodeClicked')
    onNodeClicked = new EventEmitter();

    @Output('currentEntity') currentEntity = new EventEmitter();

    treeControl = new NestedTreeControl<UserNavigationEntityDto>(node =>
        this.filteredData.filter(x => x.parentId == node.id)
    );

    filteredData: UserNavigationEntityDto[] = [];

    dataSource = new MatTreeNestedDataSource<UserNavigationEntityDto>();
    allExpanded : boolean = false;
    @ViewChild('tree') tree;
    @ViewChild('trigger') trigger: ElementRef;
    @ViewChild('body') body: ElementRef;
    constructor(public sessionState: SessionStateService,
        private userNavigationService: UserNavigationEntitiesService,
        private subscription: SubscriptionService, private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef
    ) {
        this.subscription.addOne(this.filteredData$, (data: UserNavigationEntityDto[]) => {
            this.filteredData = data;
            this.dataSource.data = data.filter(x => x.parentId == null);
            this.treeControl.dataNodes = data.filter(x => x.parentId == null);
            this.treeControl.expandAll();
            this.allExpanded = true;
        });
    }

    ngOnInit(): void {
        this.fetchEntities();
    }
    fetchEntities() {
        this.subscription.addOne(
            this.userNavigationService.get(),
            (results: UserNavigationEntityDto[]) => {
                this.entities = results;
                this.filteredData$.next(this.entities);
                this.selectedEntity = this.entities.find(x => x.isSelected === true);
                this.currentEntity.emit(this.selectedEntity);
            }
        );
    }

    hasChild = (_: number, node: UserNavigationEntityDto) =>
        this.filteredData.filter(x => x.parentId == node.id).length > 0;

    filterChanged(value) {
        this.filteredData$.next([]);
        if (value) {
            let filtered = this.entities.filter(x =>
                x.name.toLowerCase().includes(value.toLowerCase())
            );
            let childToParentNodes = [];
            if (filtered.length > 0) {
                filtered.forEach(node => {
                    childToParentNodes.push(node);
                    childToParentNodes = this.findParents(node, childToParentNodes);
                });
            }

            this.filteredData$.next(childToParentNodes);
        } else {
            this.filteredData$.next(this.entities);
        }
    }

    private findParents(node: UserNavigationEntityDto, nodes = []) {
        var parent = this.entities.find(x => x.id == node.parentId);

        if (parent) {
            if (!nodes.find(x => x.id == parent.id)) {
                nodes.push(parent);
            }

            return this.findParents(parent, nodes);
        }

        return nodes;
    }

    private getParentNodes() {
        return this.entities.filter(x => x.parentId == null);
    }

    listToTree(list) {
        var map = {},
            node,
            roots = [],
            i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentId !== null) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.parentId]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    nodeClicked(node) {
        this.selectedEntity = node;
        this.onNodeClicked.emit(node);
        this.markActiveNode(node);
    }

    markActiveNode(node: UserNavigationEntityDto) {
        node.isSelected = true;
        this.filteredData.forEach(x => {
            if (x.id != node.id) x.isSelected = false;
        });
    }



    initDateDrop(e?) {
        this.open = true;
        this.cdr.detectChanges();
        let xValue = this.trigger.nativeElement.getBoundingClientRect().left;
        let yValue = this.trigger.nativeElement.getBoundingClientRect().top;




        if ((this.trigger.nativeElement.getBoundingClientRect().left > window.innerWidth - this.trigger.nativeElement.getBoundingClientRect().width)) {
            xValue = window.innerWidth - 355;
            this.renderer.setStyle(this.body.nativeElement, 'transform', 'translate(' + xValue + 'px, ' + yValue + 'px)');
            console.log('far right');

        }
        else if (this.trigger.nativeElement.getBoundingClientRect().left < this.trigger.nativeElement.getBoundingClientRect().width) {
            xValue = 40;
            this.renderer.setStyle(this.body.nativeElement, 'transform', 'translate(' + xValue + 'px, ' + yValue + 'px)');
            console.log('far left');

        }
        else {
            this.renderer.setStyle(this.body.nativeElement, 'transform', 'translate(' + xValue + 'px, ' + yValue + 'px)');
        }
        this.renderer.setStyle(this.body.nativeElement, 'min-width', this.trigger.nativeElement.offsetWidth + 'px');


        // this.cdr.detectChanges();
        // let xValue = this.language == 'AR'? (this.trigger.nativeElement.getBoundingClientRect().left + this.trigger.nativeElement.getBoundingClientRect().width) : this.trigger.nativeElement.getBoundingClientRect().left;
        // let yValue = this.trigger.nativeElement.getBoundingClientRect().top;
        // console.log(e);
        // console.log(this.trigger.nativeElement.getBoundingClientRect());


        // this.renderer.setStyle(this.body.nativeElement, 'transform', 'translate(' + xValue + 'px, ' + yValue + 'px)');
        // this.renderer.setStyle(this.body.nativeElement, 'width', this.trigger.nativeElement.offsetWidth + 'px');
        // console.log(xValue);
        // console.log(window.innerWidth);
        // console.log(e.clientX );


        // if ((e.clientY > window.innerHeight / 2))
        //   yValue = yValue - 375;



        // if ((e.clientX > window.innerWidth - 250)){
        //   this.renderer.addClass(this.body.nativeElement, this.language == 'AR'?'left-10':'right-10');
        //   this.renderer.setStyle(this.body.nativeElement, 'transform', 'translateY('+yValue + 'px)');

        // }
        // else if(e.clientX < 200){
        //   this.renderer.setStyle(this.body.nativeElement, 'transform', 'translateY('+yValue + 'px)');
        //   this.renderer.addClass(this.body.nativeElement,  this.language == 'AR'?'left-10':'right-10');

        // }
        // else{
        // this.renderer.setStyle(this.body.nativeElement, 'transform', 'translate(' + xValue + 'px, ' + yValue + 'px)');

        // }
    }

    isExpanded(node) {
        return this.treeControl.isExpanded(node);
    }

}
