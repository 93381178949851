import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'z-radio',
  templateUrl: './zen-radio.component.html',
  styleUrls: ['./zen-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenRadioComponent,
    },
  ],
})
export class ZenRadioComponent implements ControlValueAccessor {
  @Input() label;
  @Input() required = false;
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;
  @Input() invalid = false;
  @Input() color = 'int';
  @Input() key = "key";
  @Input() options;
  @Input() language;
  selected;
  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };
  writeValue(key: any) {
    this.selected = key;
    console.log(this.selected);
  }

  handleSelection(option) {
    this.selected = option[this.key];
    this.onChange(option[this.key]);
  }
  clear(e) {
    e.stopPropagation();
    this.selected = '';
    this.onChange(this.selected);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }


}
