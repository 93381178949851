import { Component, Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'z-textarea',
  templateUrl: './zen-textarea.component.html',
  styleUrls: ['./zen-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenTextareaComponent,
    },
  ],
})
export class ZenTextareaComponent implements ControlValueAccessor {


  constructor() { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }



  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label;
  @Input() placeholder;
  @Input() maxCount
  selected;
  isDisabled: boolean = false;
  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(selected: any) {
    if (selected !== undefined && this.selected !== selected) {
      this.selected = selected;
    }
  }

  registerOnChange(fn: any) {

    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  handleChange(option) {
    this.selected = option;
    this.onChange(option);
  }


}


