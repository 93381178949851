import { SessionStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { OrgchartNodeService } from './orgchart-node.service';
import OrgChart from '@balkangraph/orgchart.js/orgchart';
import { BehaviorSubject } from 'rxjs';
import { TemplateTypes } from '../models/template-types';
import { eOrgchartTemplate } from '../models/e-orgchart-template.enum';
import { OrgchartSettingService } from './orgchart-setting.service';
import { ChartOptions } from '../models/chart-options';
import { eOrgchartOrientation } from '../models/e-orgchart-orientation.enum';

@Injectable({
    providedIn: 'root',
})
export class OrgchartTemplateService {
    constructor(
        private sessionState: SessionStateService,
        private orgChartSettingsService: OrgchartSettingService
    ) {
        this.isRtl = this.sessionState.getLanguage() == 'ar' ? true : false;

        this.sessionState.onLanguageChange$().subscribe(x => {
            if (x) {
                this.isRtl = this.sessionState.getLanguage() == 'ar' ? true : false;

                this.value = this.sessionState.getLanguage() == 'ar' ? '280' : '110';
                this.valueOneCriteria = this.sessionState.getLanguage() == 'ar' ? '280' : '120';
                this.valueF = this.sessionState.getLanguage() == 'ar' ? '280' : '20';
                this.criteriaValue = this.sessionState.getLanguage() == 'ar' ? '70' : '225';
                this.criteria = this.sessionState.getLanguage() == 'ar' ? '40' : '225';
                this.basicCriteriaValue = this.sessionState.getLanguage() == 'ar' ? '70' : '140';
                this.basicCriteriaValue1 = this.sessionState.getLanguage() == 'ar' ? '70' : '140';
                this.criteriaIcon1 = this.sessionState.getLanguage() == 'ar' ? '170' : '124';
                this.criteriaIcon = this.sessionState.getLanguage() == 'ar' ? '20' : '124';
                this.basicCriteriaIcon1 = this.sessionState.getLanguage() == 'ar' ? '170' : '24';
                this.basicCriteriaIcon = this.sessionState.getLanguage() == 'ar' ? '20' : '24';
                this.value1 = this.sessionState.getLanguage() == 'ar' ? '165' : '15';

                this.initializeDefaultTemplate();
                this.initializeDefaultSingleCriteriaTemplate();
                this.initializeDefaultTwoCriteriaTemplate();
                this.initializeBasicTemplate();
                this.initializeBasicSingleCriteriaTemplate();
                this.initializeBasicTwoCriteriaTemplate();
                this.initializeFunctionalTemplate();
                this.initializeGeneralFunctions();

                // this.InitializeGroupTemplate()
            }
        });

        if (this.sessionState.getLanguage()) {
            console.log(this.sessionState.getLanguage());
            this.value = this.sessionState.getLanguage() == 'ar' ? '280' : '110';
            this.valueOneCriteria = this.sessionState.getLanguage() == 'ar' ? '280' : '120';
            this.valueF = this.sessionState.getLanguage() == 'ar' ? '280' : '20';
            this.criteriaValue = this.sessionState.getLanguage() == 'ar' ? '70' : '225';
            this.criteria = this.sessionState.getLanguage() == 'ar' ? '40' : '225';
            this.basicCriteriaValue = this.sessionState.getLanguage() == 'ar' ? '70' : '140';
            this.basicCriteriaValue1 = this.sessionState.getLanguage() == 'ar' ? '70' : '140';
            this.criteriaIcon1 = this.sessionState.getLanguage() == 'ar' ? '170' : '124';
            this.criteriaIcon = this.sessionState.getLanguage() == 'ar' ? '20' : '124';
            this.basicCriteriaIcon1 = this.sessionState.getLanguage() == 'ar' ? '170' : '24';
            this.basicCriteriaIcon = this.sessionState.getLanguage() == 'ar' ? '20' : '24';
            this.value1 = this.sessionState.getLanguage() == 'ar' ? '165' : '15';

            this.initializeDefaultTemplate();
            this.initializeDefaultSingleCriteriaTemplate();
            this.initializeDefaultTwoCriteriaTemplate();
            this.initializeBasicTemplate();
            this.initializeBasicSingleCriteriaTemplate();
            this.initializeBasicTwoCriteriaTemplate();
            this.initializeFunctionalTemplate();
            this.initializeGeneralFunctions();
            // this.InitializeGroupTemplate()
        }

        this.initializeGroupingTemplate();
    }

    isRtl = false;
    value: string;
    valueOneCriteria: string;
    valueF: string;
    criteria: string;
    criteriaIcon1: string;
    criteriaIcon: string;
    basicCriteriaIcon1: string;
    basicCriteriaIcon: string;
    basicCriteriaValue: string;
    basicCriteriaValue1: string;
    criteriaValue: string;
    value1: string;

    //---------------------------------------------------DEFAULT TEMPLATE--------------------------------------------------------
    initializeDefaultTemplate() {
        const cardWidth = 340;
        const cardHeight = 120;
        OrgChart.templates.zenithr_default = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.zenithr_default.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_default.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_default.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
          <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="-20%" stop-color="#fff"/>
            <stop offset="100%" stop-color="#ff9000"/>
          </linearGradient>`;
        OrgChart.templates.zenithr_default.defs +=
            '<filter id="f1" > \
    <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
    </filter>';
        OrgChart.templates.zenithr_default.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
    <g>
         <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
         </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_default.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
         <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
         <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_default.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
          <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let imgX = !this.isRtl ? 20 : cardWidth - 20 - 60;
        OrgChart.templates.zenithr_default.img_0 = `<foreignObject x="${imgX}" y="20" width="60" height="80" style="border-radius:2px">
            {val}
          </foreignObject>`;

        let nameX = !this.isRtl ? 100 : 20;
        let nameWidth = cardWidth - 120;
        OrgChart.templates.zenithr_default[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_default['employeeId'] = `
             <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                 <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                     ID: {val}
                 </div>
             </foreignObject>
                 `;

        OrgChart.templates.zenithr_default[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        //         OrgChart.templates.zenithr_default.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //          <text text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
        //          <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        //         OrgChart.templates.zenithr_default.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //          <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        //         OrgChart.templates.zenithr_default['header'] = `{val}`;

        //         //Direct Reportees
        //         OrgChart.templates.zenithr_default['directreportees'] = `
        //       <g data-toggle="tooltip" title="Direct Reports">
        //           <rect stroke="#fff" stroke-width="2" x="274" y="2" width="24" height="24" fill="#eee" rx="8" ry="8"></rect>
        //           <text id="x" style="font-size: 14px; font-weight:600; letter-spacing: 0.04rem" dx="11" fill="#888" x="275" y="18" text-anchor="middle">{val}</text>
        //       </g>  `;

        //         OrgChart.templates.zenithr_default['employeeId'] = `
        //   <foreignObject width="210px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${this.value}" y="20">
        // <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
        //   ID: {val}
        //   </div>
        //   </foreignObject>
        //       `;
        //         OrgChart.templates.zenithr_default[
        //             'name'
        //         ] = `<foreignObject width="210px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" x="${this.value}" y="45">{val}</foreignObject>`;
        //         OrgChart.templates.zenithr_default[
        //             'jobTitle'
        //         ] = `<foreignObject width="210px" height="14" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99" x="${this.value}" y="67">{val}</foreignObject>`;
        //         OrgChart.templates.zenithr_default[
        //             'department'
        //         ] = `<text data-width="170" data-text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa" text-anchor="start" x="${this.value}" y="100">{val}</text>`;
        //         OrgChart.templates.zenithr_default[
        //             'value'
        //         ] = `<text style="font-size: 16px; font-weight: 500" fill="#666" x="${this.value}" text-anchor="start" y="175">{val}</text>`;
        //         OrgChart.templates.zenithr_default[
        //             'profileUrl'
        //         ] = `<a x="10" y="10" text-anchor="start" target="_blank" xlink:href="{val}"></a>`;
        //         OrgChart.templates.zenithr_default['img_0'] = `{val}`;

        this.setupOrientation('zenithr_default');
    }

    //---------------------------------------------------DEFAULT (SINGLE CRITERIA) TEMPLATE--------------------------------------------------------

    initializeDefaultSingleCriteriaTemplate() {
        const cardWidth = 340;
        const cardHeight = 164;
        OrgChart.templates.zenithr_default_single_criteria = Object.assign(
            {},
            OrgChart.templates.ana
        );
        OrgChart.templates.zenithr_default_single_criteria.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_default_single_criteria.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_default_single_criteria.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="-20%" stop-color="#fff"/>
          <stop offset="100%" stop-color="#ff9000"/>
        </linearGradient>`;
        OrgChart.templates.zenithr_default_single_criteria.defs +=
            '<filter id="f1" > \
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
        </filter>';
        OrgChart.templates.zenithr_default_single_criteria.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
        <g>
             <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
             </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_default_single_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
        <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_default_single_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
         <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let imgX = !this.isRtl ? 20 : cardWidth - 20 - 60;
        OrgChart.templates.zenithr_default_single_criteria.img_0 = `<foreignObject x="${imgX}" y="20" width="60" height="80" style="border-radius:2px">
           {val}
         </foreignObject>`;

        let nameX = !this.isRtl ? 100 : 20;
        let nameWidth = cardWidth - 120;
        OrgChart.templates.zenithr_default_single_criteria[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_default_single_criteria['employeeId'] = `
            <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                    ID: {val}
                </div>
            </foreignObject>
                `;

        OrgChart.templates.zenithr_default_single_criteria[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        //     OrgChart.templates.zenithr_default_single_criteria['employeeId'] = `
        //     <foreignObject width="210px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${this.valueOneCriteria}" y="20">
        //   <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
        //     ID: {val}
        //     </div>
        //     </foreignObject>
        //         `;

        //     OrgChart.templates.zenithr_default_single_criteria[
        //         'name'
        //     ] = `<foreignObject width="210px" height="17" style="font:normal normal 500 14px/20px Rubik; letter-spacing: 0px; opacity:1; color:#46597A" x="${this.valueOneCriteria}" y="45">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_default_single_criteria[
        //         'jobTitle'
        //     ] = `<foreignObject width="210px" height="14"  x="${this.valueOneCriteria}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_default_single_criteria[
        //         'department'
        //     ] = `<text width="170" text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa" x="${this.valueOneCriteria}" text-anchor="start" y="100">{val}</text>`;
        //     //Direct Reportees
        //     OrgChart.templates.zenithr_default_single_criteria[
        //         'directreportees'
        //     ] = `<g data-toggle="tooltip" title="Direct Reports">
        //     <rect stroke="#fff" stroke-width="2" x="272" y="2" width="26" height="26" fill="#eee" rx="8" ry="8"></rect>
        //     <text id="x" style="font-size: 16px; font-weight:600; letter-spacing: 0.04rem" dx="10" fill="#888" x="275" y="20" text-anchor="middle">{val}</text>
        // </g>`;
        //     OrgChart.templates.zenithr_default_single_criteria.img_0 = `{val}`;
        // OrgChart.templates.zenithr_default_single_criteria['header'] = `{val}`;
        OrgChart.templates.zenithr_default_single_criteria['criteria_1'] = `{val}`;

        // //criteria 1
        // OrgChart.templates.zenithr_default_single_criteria[
        //     'criteria_icon_1'
        // ] = `<foreignobject width="16" height="16" x="${this.criteriaIcon1}" text-anchor="start"  y="105">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_default_single_criteria[
        //     'criteria_name_1'
        // ] = `<text style="font-size: 12px; font-weight:500;" fill="#aaa" text-anchor="start"  x="${this.valueOneCriteria}"  y="155">{val}</text>`;
        // OrgChart.templates.zenithr_default_single_criteria[
        //     'criteria_value_1'
        // ] = `<foreignobject width="90" height="14" x="${this.criteriaValue}"  y="106" style="font:normal normal 600 11px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;

        this.setupOrientation('zenithr_default_single_criteria');
    }

    //---------------------------------------------------DEFAULT (TWO CRITERIA) TEMPLATE--------------------------------------------------------

    initializeDefaultTwoCriteriaTemplate() {
        const cardWidth = 340;
        const cardHeight = 192;
        OrgChart.templates.zenithr_default_two_criteria = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.zenithr_default_two_criteria.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_default_two_criteria.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_default_two_criteria.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="-20%" stop-color="#fff"/>
          <stop offset="100%" stop-color="#ff9000"/>
        </linearGradient>`;
        OrgChart.templates.zenithr_default_two_criteria.defs +=
            '<filter id="f1" > \
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
        </filter>';
        OrgChart.templates.zenithr_default_two_criteria.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
    <g>
         <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
         </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_default_two_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
        <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_default_two_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
         <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let imgX = !this.isRtl ? 20 : cardWidth - 20 - 60;
        OrgChart.templates.zenithr_default_two_criteria.img_0 = `<foreignObject x="${imgX}" y="20" width="60" height="80" style="border-radius:2px">
           {val}
         </foreignObject>`;

        let nameX = !this.isRtl ? 100 : 20;
        let nameWidth = cardWidth - 120;
        OrgChart.templates.zenithr_default_two_criteria[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_default_two_criteria['employeeId'] = `
            <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                    ID: {val}
                </div>
            </foreignObject>
                `;

        OrgChart.templates.zenithr_default_two_criteria[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        //     OrgChart.templates.zenithr_default_two_criteria[
        //         'name'
        //     ] = `<foreignObject width="210px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" x="${this.valueOneCriteria}" y="45">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_default_two_criteria[
        //         'jobTitle'
        //     ] = `<foreignObject width="210px" height="14" x="${this.valueOneCriteria}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_default_two_criteria[
        //         'department'
        //     ] = `<text data-width="170" data-text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa"  text-anchor="start"  x="${this.value}" y="100">{val}</text>`;
        //     //Employee Image
        //     OrgChart.templates.zenithr_default_two_criteria.img_0 = `{val}`;
        //     //Direct Reportees
        //     OrgChart.templates.zenithr_default_two_criteria['directreportees'] = `
        // <g data-toggle="tooltip" title="Direct Reports">
        //     <rect stroke="#fff" stroke-width="2" x="272" y="2" width="26" height="26" fill="#eee" rx="8" ry="8"></rect>
        //     <text id="x" style="font-size: 16px; font-weight:600; letter-spacing: 0.04rem" dx="10" fill="#888" x="275" y="20" text-anchor="middle">{val}</text>
        // </g>`;

        // //criteria 1
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_icon_1'
        // ] = `<foreignobject width="16" height="16" x="${this.criteriaIcon1}" text-anchor="start"  y="105">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_name_1'
        // ] = `<text style="font-size: 12px; font-weight:500;" fill="#aaa" text-anchor="start"  x="${this.valueOneCriteria}"  y="155">{val}</text>`;
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_value_1'
        // ] = `<foreignobject width="90" height="14" x="${this.criteriaValue}"  y="106" style="font:normal normal 600 12px/16px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;
        // //criteria 2
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_icon_2'
        // ] = `<foreignobject width="16"  height="16" x="${this.criteriaIcon}" y="130">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_name_2'
        // ] = `<text style="font-size: 12px; font-weight:500;"  text-anchor="start"  fill="#aaa" x="${this.criteria}" y="150">{val}</text>`;
        // OrgChart.templates.zenithr_default_two_criteria[
        //     'criteria_value_2'
        // ] = `<foreignobject width="90" height="14" x="${this.criteria}"  y="131" style="font:normal normal 600 12px/16px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;

        // OrgChart.templates.zenithr_default_two_criteria['header'] = `{val}`;
        OrgChart.templates.zenithr_default_two_criteria['criteria_1'] = `{val}`;
        OrgChart.templates.zenithr_default_two_criteria['criteria_2'] = `{val}`;

        this.setupOrientation('zenithr_default_two_criteria');
    }

    //---------------------------------------------------BASIC TEMPLATE--------------------------------------------------------

    initializeBasicTemplate() {
        const cardWidth = 340;
        const cardHeight = 120;
        OrgChart.templates.zenithr_basic = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.zenithr_basic.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_basic.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_basic.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
                  <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="-20%" stop-color="#fff"/>
                    <stop offset="100%" stop-color="#ff9000"/>
                  </linearGradient>`;
        OrgChart.templates.zenithr_basic.defs +=
            '<filter id="f1" > \
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
            </filter>';
        OrgChart.templates.zenithr_basic.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
            <g>
                 <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
                 </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_basic.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                 <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
                 <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_basic.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                  <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let nameX = !this.isRtl ? 20 : 20;
        let nameWidth = cardWidth - 40;
        OrgChart.templates.zenithr_basic[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="text-align:center;font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_basic['employeeId'] = `
                     <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style="text-align:center; color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                         <div style="margin:0 auto;background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                             ID: {val}
                         </div>
                     </foreignObject>
                         `;

        OrgChart.templates.zenithr_basic[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="text-align:center;font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        // OrgChart.templates.zenithr_basic.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        // <text text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
        //          <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        // OrgChart.templates.zenithr_basic.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //          <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        // //Direct Reportees
        // OrgChart.templates.zenithr_basic['directreportees'] = `
        //       <g data-toggle="tooltip" title="Direct Reports">
        //           <rect stroke="#fff" stroke-width="2" x="274" y="2" width="24" height="24" fill="#eee" rx="8" ry="8"></rect>
        //           <text id="x" style="font-size: 14px; font-weight:600; letter-spacing: 0.04rem" dx="11" fill="#888" x="275" y="18" text-anchor="middle">{val}</text>
        //       </g>  `;

        // OrgChart.templates.zenithr_basic['employeeId'] = `
        // <foreignObject width="210px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="144" y="20">
        //     <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
        // ID: {val}
        // </div>
        // </foreignObject>
        //     `;

        // OrgChart.templates.zenithr_basic[
        //     'name'
        // ] = `<foreignObject width="300px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A;text-align: center;" x="${this.valueF}" y="45">{val}</foreignObject>`;
        // OrgChart.templates.zenithr_basic[
        //     'jobTitle'
        // ] = `<foreignObject width="300px" height="14" x="${this.valueF}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99; text-align:center;">{val}</foreignObject>`;
        // OrgChart.templates.zenithr_basic[
        //     'department'
        // ] = `<text data-width="170" data-text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa" text-anchor="start" x="${this.value}" y="100">{val}</text>`;
        // OrgChart.templates.zenithr_basic[
        //     'value'
        // ] = `<text style="font-size: 16px; font-weight: 500" fill="#666" x="${this.value}" text-anchor="start" y="175">{val}</text>`;
        // OrgChart.templates.zenithr_basic[
        //     'profileUrl'
        // ] = `<a x="10" y="10" text-anchor="start" target="_blank" xlink:href="{val}"></a>`;
        OrgChart.templates.zenithr_basic['img_0'] = ``;

        this.setupOrientation('zenithr_basic');

        // console.log(OrgChart.templates);
    }

    //---------------------------------------------------BASIC (SINGLE CRITERIA) TEMPLATE--------------------------------------------------------

    initializeBasicSingleCriteriaTemplate() {
        const cardWidth = 340;
        const cardHeight = 164;
        OrgChart.templates.zenithr_basic_single_criteria = Object.assign(
            {},
            OrgChart.templates.ana
        );
        OrgChart.templates.zenithr_basic_single_criteria.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_basic_single_criteria.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_basic_single_criteria.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
                <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="-20%" stop-color="#fff"/>
                  <stop offset="100%" stop-color="#ff9000"/>
                </linearGradient>`;
        OrgChart.templates.zenithr_basic_single_criteria.defs +=
            '<filter id="f1" > \
                <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
                </filter>';
        OrgChart.templates.zenithr_basic_single_criteria.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
                <g>
                     <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
                     </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_basic_single_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                 <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
                 <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_basic_single_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                  <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let nameX = !this.isRtl ? 20 : 20;
        let nameWidth = cardWidth - 40;
        OrgChart.templates.zenithr_basic_single_criteria[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="text-align:center;font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_basic_single_criteria['employeeId'] = `
                     <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style="text-align:center; color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                         <div style="margin:0 auto;background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                             ID: {val}
                         </div>
                     </foreignObject>
                         `;

        OrgChart.templates.zenithr_basic_single_criteria[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="text-align:center;font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        //         OrgChart.templates.zenithr_basic_single_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //         <text text-anchor="middle" style="font:normal normal 500 10px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
        //                  <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        //         OrgChart.templates.zenithr_basic_single_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //                  <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        //         OrgChart.templates.zenithr_basic_single_criteria['employeeId'] = `
        //   <foreignObject width="210px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="144" y="20">
        // <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
        //   ID: {val}
        //   </div>
        //   </foreignObject>
        //       `;

        //         OrgChart.templates.zenithr_basic_single_criteria[
        //             'name'
        //         ] = `<foreignObject width="300px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A;text-align: center;" x="${this.valueF}" y="45">{val}</foreignObject>`;
        //         OrgChart.templates.zenithr_basic_single_criteria[
        //             'jobTitle'
        //         ] = `<foreignObject width="300px" height="14" x="${this.valueF}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99; text-align:center;">{val}</foreignObject>`;
        //         OrgChart.templates.zenithr_basic_single_criteria[
        //             'department'
        //         ] = `<text width="170" text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa" x="${this.valueOneCriteria}" text-anchor="start" y="100">{val}</text>`;
        //         //Direct Reportees
        //         OrgChart.templates.zenithr_basic_single_criteria[
        //             'directreportees'
        //         ] = `<g data-toggle="tooltip" title="Direct Reports">
        //                 <rect stroke="#fff" stroke-width="2" x="272" y="2" width="26" height="26" fill="#eee" rx="8" ry="8"></rect>
        //                 <text id="x" style="font-size: 16px; font-weight:600; letter-spacing: 0.04rem" dx="10" fill="#888" x="275" y="20" text-anchor="middle">{val}</text>
        //             </g>`;
        OrgChart.templates.zenithr_basic_single_criteria.img_0 = ``;
        // //criteria 1
        // OrgChart.templates.zenithr_basic_single_criteria[
        //     'criteria_icon_1'
        // ] = `<foreignobject width="16" height="16" x="${this.basicCriteriaIcon1}" text-anchor="start"  y="105">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_basic_single_criteria[
        //     'criteria_name_1'
        // ] = `<text style="font-size: 12px; font-weight:500;" fill="#aaa" text-anchor="start"  x="${this.valueOneCriteria}"  y="155">{val}</text>`;
        // OrgChart.templates.zenithr_basic_single_criteria[
        //     'criteria_value_1'
        // ] = `<foreignobject width="175" height="14" x="${this.basicCriteriaValue}"   y="106" style="font:normal normal 600 12px/16px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;

        // OrgChart.templates.zenithr_basic_single_criteria['header'] = `{val}`;
        OrgChart.templates.zenithr_basic_single_criteria['criteria_1'] = `{val}`;

        this.setupOrientation('zenithr_basic_single_criteria');
    }

    //---------------------------------------------------BASIC (TWO CRITERIA) TEMPLATE--------------------------------------------------------

    initializeBasicTwoCriteriaTemplate() {
        const cardWidth = 340;
        const cardHeight = 192;
        OrgChart.templates.zenithr_basic_two_criteria = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.zenithr_basic_two_criteria.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_basic_two_criteria.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_basic_two_criteria.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
            <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="-20%" stop-color="#fff"/>
              <stop offset="100%" stop-color="#ff9000"/>
            </linearGradient>`;
        OrgChart.templates.zenithr_basic_two_criteria.defs +=
            '<filter id="f1" > \
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
            </filter>';
        OrgChart.templates.zenithr_basic_two_criteria.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
        <g>
             <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
             </g>  `;

        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_basic_two_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                      <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
                      <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_basic_two_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                       <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        let nameX = !this.isRtl ? 20 : 20;
        let nameWidth = cardWidth - 40;
        OrgChart.templates.zenithr_basic_two_criteria[
            'name'
        ] = `<foreignObject x="${nameX}" y="45" width="${nameWidth}px" height="17" style="text-align:center;font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A" >{val}</foreignObject>`;

        OrgChart.templates.zenithr_basic_two_criteria['employeeId'] = `
                          <foreignObject width="${nameWidth}px" height="15px" data-width="170" data-text-overflow="ellipsis" style="text-align:center; color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="${nameX}" y="20">
                              <div style="margin:0 auto;background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
                                  ID: {val}
                              </div>
                          </foreignObject>
                              `;

        OrgChart.templates.zenithr_basic_two_criteria[
            'jobTitle'
        ] = `<foreignObject width="${nameWidth}px" height="14" x="${nameX}" y="67" style="text-align:center;font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99">{val}</foreignObject>`;

        //     OrgChart.templates.zenithr_basic_two_criteria.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //     <text text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
        //          <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        //     OrgChart.templates.zenithr_basic_two_criteria.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //          <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        //     OrgChart.templates.zenithr_basic_two_criteria['employeeId'] = `
        //     <foreignObject width="210px" height="15px" data-width="170" data-text-overflow="ellipsis" style=" color:#586F99;letter-spacing: 0px; opacity: 1; text-align: center; font: normal normal normal 10px/16px Rubik;" fill="#586F99" x="144" y="20">
        //   <div style="background-color:rgba(70, 89, 122, 0.1) ; border-radius: 2px 2px 3px 3px; width:fit-content; padding-inline:4px;">
        //     ID: {val}
        //     </div>
        //     </foreignObject>
        //         `;

        //     // --------------Filter----------------------------------------
        //     //       `<svg>
        //     //       <defs>
        //     //     <filter x="-5%" width="110%" y="0%" height="100%" id="solid">
        //     //       <feFlood flood-opacity="0.1" flood-color="#46597A" result="bg" />
        //     //       <feGaussianBlur stdDeviation="2"/>
        //     //       <feMerge>
        //     //         <feMergeNode in="bg"/>
        //     //         <feMergeNode in="SourceGraphic"/>
        //     //       </feMerge>
        //     //     </filter>
        //     //   </defs>
        //     //   </svg>`

        //     OrgChart.templates.zenithr_basic_two_criteria[
        //         'name'
        //     ] = `<foreignObject width="300px" height="17" style="font:normal normal 500 14px/20px Rubik;letter-spacing: 0px; opacity:1; color:#46597A;text-align: center;" x="${this.valueF}" y="45">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_basic_two_criteria[
        //         'jobTitle'
        //     ] = `<foreignObject width="300px" height="14" x="${this.valueF}" y="67" style="font:normal normal normal 12px/16px Rubik;  letter-spacing:0; opacity:1; color:#586F99; text-align:center;">{val}</foreignObject>`;
        //     OrgChart.templates.zenithr_basic_two_criteria[
        //         'department'
        //     ] = `<text data-width="170" data-text-overflow="ellipsis" style="font-size: 12px;" fill="#aaa"  text-anchor="start"  x="${this.value}" y="100">{val}</text>`;
        //Employee Image
        OrgChart.templates.zenithr_basic_two_criteria.img_0 = ``;
        //Direct Reportees
        // OrgChart.templates.zenithr_basic_two_criteria['directreportees'] = `
        // <g data-toggle="tooltip" title="Direct Reports">
        //     <rect stroke="#fff" stroke-width="2" x="272" y="2" width="26" height="26" fill="#eee" rx="8" ry="8"></rect>
        //     <text id="x" style="font-size: 16px; font-weight:600; letter-spacing: 0.04rem" dx="10" fill="#888" x="275" y="20" text-anchor="middle">{val}</text>
        // </g>`;
        // //criteria 1
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_icon_1'
        // ] = `<foreignobject width="16" height="16" x="${this.basicCriteriaIcon1}" text-anchor="start"  y="105">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_name_1'
        // ] = `<text style="font-size: 12px; font-weight:500;" fill="#aaa" text-anchor="start"  x="${this.valueOneCriteria}"  y="155">{val}</text>`;
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_value_1'
        // ] = `<foreignobject width="175" height="14" x="${this.basicCriteriaValue}"   y="106" style="font:normal normal 600 12px/16px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;
        // //criteria 2
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_icon_2'
        // ] = `<foreignobject width="16"  height="16" x="${this.basicCriteriaIcon}" y="130">{val}</foreignobject>`;
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_name_2'
        // ] = `<text style="font-size: 12px; font-weight:500;"  text-anchor="start"  fill="#aaa" x="${this.criteria}" y="150">{val}</text>`;
        // OrgChart.templates.zenithr_basic_two_criteria[
        //     'criteria_value_2'
        // ] = `<foreignobject width="175" height="14" x="${this.basicCriteriaValue1}"  text-anchor="start"  y="131" style="font:normal normal 600 12px/16px Rubik;font-size: 12px; color: #46597A;opacity: 1;letter-spacing: 0px; text-align:right;">{val}</foreignobject>`;

        // OrgChart.templates.zenithr_basic_two_criteria['header'] = `{val}`;
        OrgChart.templates.zenithr_basic_two_criteria['criteria_1'] = `{val}`;
        OrgChart.templates.zenithr_basic_two_criteria['criteria_2'] = `{val}`;

        this.setupOrientation('zenithr_basic_two_criteria');
    }

    initializeGroupingTemplate() {
        const templateName = eOrgchartTemplate.Grouping;
        console.log(OrgChart.templates.group);
        OrgChart.templates[templateName] = Object.assign({}, OrgChart.templates.group);
        // OrgChart.templates[templateName].svg =
        //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        // OrgChart.templates[templateName].size = [340, 152];
        OrgChart.templates[templateName].link =
            '<path stroke-linejoin="round" stroke="#DADEE4" stroke-width="1px" fill="none" d="{rounded}" />';
        // OrgChart.templates[
        //     templateName
        // ].defs = `<filter x="-50%" y="-50%" width="{w}" height="{h}" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
        // <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
        //   <stop offset="-20%" stop-color="#fff"/>
        //   <stop offset="100%" stop-color="#ff9000"/>
        // </linearGradient>`;
        // OrgChart.templates[templateName].defs +=
        //     '<filter id="f1" > \
        // <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
        // </filter>';
        // OrgChart.templates[templateName].node = `
        // <rect rx="10" ry="10" x="0" y="0" height="{h}" width="{w}" fill="#f2f2f2" stroke-width="0" filter="url(#shadow)"></rect>
        // `;
        OrgChart.templates[templateName].node = '';

        // OrgChart.templates[
        //     templateName
        // ].field_0 = `<text style="font-size: 14px; font-weight:500; " fill="#46597A" x="20" y="25">{val}</text>`;

        // OrgChart.templates[
        //     templateName
        // ].numberOfNodes = `<text style="font-size: 14px; font-weight:500;" fill="#46597A" x="" y="25">{val}</text>`;
        OrgChart.templates[templateName].top = `{val}`;
        OrgChart.templates[templateName].background = '{val}';

        OrgChart.templates[
            templateName
        ].plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        <text text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
             <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates[
            templateName
        ].minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
             <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        this.setupOrientation(templateName);

        OrgChart.templates[templateName].min = Object.assign({}, OrgChart.templates[templateName]);
        OrgChart.templates[templateName].min.size = [400, 70];

        this.setupOrientation(templateName, true);
    }

    initializeFunctionalTemplate() {
        const cardWidth = 340;
        const cardHeight = 152;
        OrgChart.templates.zenithr_functional = Object.assign({}, OrgChart.templates.ana);
        OrgChart.templates.zenithr_functional.svg =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
        OrgChart.templates.zenithr_functional.size = [cardWidth, cardHeight];
        OrgChart.templates.zenithr_functional.defs = `<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="-20%" stop-color="#fff"/>
          <stop offset="100%" stop-color="#ff9000"/>
        </linearGradient>`;
        OrgChart.templates.zenithr_functional.defs +=
            '<filter id="f1" > \
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" /> \
        </filter>';
        OrgChart.templates.zenithr_functional.node = `<svg height="0" width="0"><filter id='shadow' color-interpolation-filters="sRGB"><feDropShadow flood-color="#46597A" dx="0" dy="2" stdDeviation="2" flood-opacity="0.2"/></filter></svg>
        <g>
             <rect filter="url(#shadow)" x="0" y="0" width="${cardWidth}" height="${cardHeight}" fill="#FFFFFF" rx="10" ry="10"></rect>
             </g>  `;
        // OrgChart.templates.zenithr_functional.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        // <text text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
        //      <text x="19" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        // OrgChart.templates.zenithr_functional.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
        //      <text x="11" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;
        let arrowX = !this.isRtl ? 17 : 27;
        OrgChart.templates.zenithr_functional.plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                      <text x="8" y="28" text-anchor="middle" style="font:normal normal 500 11px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" >{collapsed-children-count}</text>
                      <text x="${arrowX}" y="29" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

        OrgChart.templates.zenithr_functional.minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                       <text x="${arrowX - 7
            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;

        OrgChart.templates.zenithr_functional[
            'name'
        ] = `<foreignObject width="300px" height="40" style="font-size: 16px; letter-spacing:0; opacity:1; color:#46597A; text-align: center;" x="20" y="59"><div style="font:normal 14px Rubik; width:100%; height:100%">{val}</div></foreignObject>`;

        OrgChart.templates.zenithr_functional['type'] = `{val}`;
        OrgChart.templates.zenithr_functional.img_0 = ``;
        OrgChart.templates.zenithr_functional['noOfEntitiesReporting'] = `{val}`;
        OrgChart.templates.zenithr_functional['totalHeadCount'] = `{val}`;
        OrgChart.templates.zenithr_functional.nodeMenuButton = `<foreignobject transform="matrix(1,0,0,1,260,20)" control-node-menu-id="{id}" x="298" y="28" fill="#000000" fill-opacity="0" width="29" height="29"><a class="btn btn-sm functional-node-menu"><i class="fas fa-ellipsis-v"></i></a></foreignobject>`;
        OrgChart.templates.zenithr_functional['showEntityDrillDownButton'] = `{val}`;

        this.setupOrientation('zenithr_functional');
    }

    initializeGeneralFunctions() {
        OrgChart['searchUI'].createItem = function (t, e, r, i) {
            return (
                r && (r = '<b>' + r + '</b>'),
                `<tr data-search-item-id="${e}">\n <td class="boc-search-image-td">\n             ${(t =
                    t
                        ? `<div class="boc-search-photo"> ${t}</div>`
                        : `<div class="boc-search-photo">${OrgChart.icon.user(
                            32,
                            32,
                            '#DADEE4'
                        )}</div>`)}\n    </td>\n  <td class="boc-search-text-td">${r} <br/>${i}</td>\n </tr>`
            );
        };

        // OrgChart['searchUI'].createItem = function (img, item) {
        //
        //   var data = item.node.id.data;
        //   var name = data.fullName;
        //   var fullNameArray = name.split(' ');
        //   var initials = data.initials;
        //   return `
        //         <div data-search-item-id="${item.id}">
        //             <div class="org-results-img" data-initials="${initials}">
        //                 <img src="${data.imgURL}" onerror="HandleOrgchartFailedImageLoad(this)" />
        //             </div>
        //             <div class="emp-details">
        //                 <span class="emp-id">${item.id}</span>
        //                 <span class="emp-name">${name}</span>
        //                 <span class="emp-entity-name">${data.entityName}</span>
        //             </div>
        //         </div>
        //         `;
        // };
    }
    InitializeGroupTemplate() { }

    private nodeLevelChartSource = new BehaviorSubject<any>(null);
    nodeLevelChart$ = this.nodeLevelChartSource.asObservable();
    setMaxLevelChart(level: any) {
        this.nodeLevelChartSource.next(level);
    }

    getTemplate(templateType: string, criteriaCount: number) {
        let template = eOrgchartTemplate.Default;
        if (templateType === TemplateTypes.Default) {
            switch (criteriaCount) {
                case 1:
                    template = eOrgchartTemplate.SingleCriteria;
                    break;
                case 2:
                    template = eOrgchartTemplate.TwoCriteria;
                    break;
                default:
                    template = eOrgchartTemplate.Default;
                    break;
            }
        } else {
            switch (criteriaCount) {
                case 1:
                    template = eOrgchartTemplate.BasicSingleCriteria;
                    break;
                case 2:
                    template = eOrgchartTemplate.BasicTwoCriteria;
                    break;
                default:
                    template = eOrgchartTemplate.Basic;
                    break;
            }
        }

        return template;
    }

    setupOrientation(template, isMin = false) {
        this.orgChartSettingsService.options$.subscribe(x => {
            let plus = '';
            let minus = '';
            let arrowX = !this.isRtl ? 17 : 27;

            if (x.key === ChartOptions.Orientation) {
                switch (x.value) {
                    case eOrgchartOrientation.Right:
                        arrowX = !this.isRtl ? 2 : 10;
                        plus = `<foreignObject x="-5" y="-15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="transform: rotate(90deg);box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                        <text text-anchor="middle" style="font:normal normal 500 10px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="5" y="13">{collapsed-children-count}</text>
                        <text x="${arrowX}" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf104</text>`;

                        minus = `<foreignObject x="-5" y="-15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="transform:rotate(90deg);box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                        <text x="${arrowX}" y="20" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf104</text>`;
                        break;

                    case eOrgchartOrientation.Left:
                        arrowX = !this.isRtl ? 21 : 28;
                        plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="transform: rotate(90deg) scaleY(-1);box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
         <text text-anchor="middle" style="font:normal normal 500 10px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="24" y="13">{collapsed-children-count}</text>
         <text x="${arrowX}" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf105</text>`;

                        minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="transform:rotate(90deg) scaleY(-1);box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                        <text x="${arrowX}" y="20" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf105</text>`;
                        break;
                        break;

                    default:
                        plus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                        <text text-anchor="middle" style="font:normal normal 500 10px Rubik; opacity:1; cursor:pointer; text-align: center;" fill="#586F99" x="9" y="29">{collapsed-children-count}</text>
                             <text x="${arrowX}" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf107</text>`;

                        minus = `<foreignObject x="-5" y="15" width="40px" height="20px" rx="8" ry="8" fill="#FFFFFF" stroke-width="1" style="box-shadow: 0px 2px 4px #46597A33;border-radius: 0px 0px 10px 10px;opacity: 1;background: #FFFFFF 0% 0% no-repeat padding-box;"><rect></rect></foreignObject>
                             <text x="${arrowX - 7
                            }" y="30" style="font-family:'Font Awesome 6 Pro'; cursor:pointer;" fill="#586F99">&#xf106</text>`;
                        break;
                }

                OrgChart.templates[template].plus = plus;
                OrgChart.templates[template].minus = minus;

                if (isMin) {
                    OrgChart.templates[template].min.plus = plus;
                    OrgChart.templates[template].min.minus = minus;
                }
            }
        });
    }
}
