import type { ChangePasswordDto, ForgetPasswordRequestDto, ResetPasswordDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomAccountService {
  apiName = 'Default';

  changePasswordByInput = (input: ChangePasswordDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/intelligence/custom-account/change-password',
      body: input,
    },
    { apiName: this.apiName });

  forgetPasswordRequestByInput = (input: ForgetPasswordRequestDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/intelligence/custom-account/forget-password-request',
      body: input,
    },
    { apiName: this.apiName });

  resetPasswordByInput = (input: ResetPasswordDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/intelligence/custom-account/reset-password-request',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
