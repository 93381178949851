import { Directive, HostBinding, Input } from '@angular/core';
import { ZenTabsComponent } from 'src/app/shared/components/zen-tabs/zen-tabs.component';

@Directive({
    selector: 'zen-tabs[subTab]',
})
export class SubTabDirective {
    @Input('class') class = 'test test-x';

    @HostBinding('class')
    get classes(): string {
        return `${this.class}`;
    }
    constructor(private zenTabs: ZenTabsComponent) {
        setTimeout(() => {
            zenTabs.zenTabsContainer.nativeElement.classList.add(
                'flex',
                'flex-row',
                'w-full',
                'overflow-hidden',
            );
            zenTabs.zenTabsContentContainer.nativeElement.classList.add(
                'p-7'
            );
        }, 0);
    }
}
