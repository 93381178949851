import { SessionStateService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import {
  EmpEducationDto,
  EmployeeProfileService,
} from '@proxy/intelligence/employee-profile';
import { DataService } from '../../services/data.service';
import { EmployeeDataService } from '../../services/employee-data.service';

@Component({
  selector: 'app-qualifications-tab',
  templateUrl: './qualifications-tab.component.html',
  styleUrls: ['./qualifications-tab.component.scss'],
})
export class QualificationsTabComponent implements OnInit {
  qualifications: EmpEducationDto[];
  employeeId
  @Input('employeeId') set employee (id:any){
    console.log(id);
    
    if(id){
    this.employeeId = id;
    this.employeeProfileService
    .getEmpEducationByEmployeeId(id)
    .subscribe(data => {
      this.qualifications = data;
      console.log(data);
      
    });
  }
  };
  constructor(
    private dataService: DataService,
    private employeeProfileService: EmployeeProfileService,
    public sessionState: SessionStateService,
  ) {}

  ngOnInit(): void {
  
  }
}
