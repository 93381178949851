import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberFormattingService {

  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) { }

  formatNumber(value, decimals = 0) {
    return this.decimalPipe.transform(value, `1.${decimals}-${decimals}`)
  }

  formatCurrency(value, decimals = 0) {
    return this.currencyPipe.transform(value, null, "code", `1.${decimals}-${decimals}`);
  }

  formatMoney(value, decimals = 0) {
    return this.currencyPipe.transform(value, null, "", `1.${decimals}-${decimals}`);
  }
}
