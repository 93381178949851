import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartOption } from '../models/chart-option';

@Injectable({
    providedIn: 'root',
})
export class OrgchartSettingService {
    private _stateSetting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public state$ = this._stateSetting.asObservable();
    private _stateDrawer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public Drawer$ = this._stateDrawer.asObservable();
    private _zoomChart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public zoomChart$ = this._zoomChart.asObservable();
    private _fitChart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public fitChart$ = this._fitChart.asObservable();

    private _options: BehaviorSubject<ChartOption> = new BehaviorSubject<ChartOption>({
        key: '',
        value: '',
    });
    public options$ = this._options.asObservable();

    constructor() {}

    setstateOpen(status: boolean) {
        this._stateSetting.next(status);
    }

    setDrawerOpen(status: boolean) {
        this._stateDrawer.next(status);
    }

    setZoom(zoom: boolean) {
        this._zoomChart.next(zoom);
    }

    setFit(expand: boolean) {
        this._fitChart.next(expand);
    }

    setOption(option: ChartOption) {
        this._options.next(option);
    }
}
