import { Component, Input, OnInit } from '@angular/core';
import { ZenTabsComponent } from '../zen-tabs.component';

@Component({
    selector: 'zen-tab',
    templateUrl: './zen-tab.component.html',
    styleUrls: ['./zen-tab.component.scss'],
})
export class ZenTabComponent implements OnInit {
    @Input('tabTitle')
    tabTitle;

    @Input() template;

    @Input() dataContext;

    @Input() active: boolean = false;

    @Input() titleTemplate;
    @Input() titleDataContext;

    ngOnInit(): void {}
}
