import {
    ConfigStateService,
    LanguageInfo,
    SubscriptionService,
} from '@abp/ng.core';
import { Component, Renderer2 } from '@angular/core';
import { fadeIn } from './shared/animations';
import { LoaderService } from './shared/loader.service';
import { Observable } from 'rxjs';
import { MetaService } from './shared/services/meta.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeIn],
    providers: [SubscriptionService],
})
export class AppComponent {
    header = 'header';
    currencies;
    currentCurrency;
    showLoader;
    languages$: Observable<LanguageInfo[]> = this.configState.getDeep$('localization.languages');

    get smallScreen(): boolean {
        return window.innerWidth < 992;
    }

    constructor(
        private configState: ConfigStateService,
        public loaderService: LoaderService,
        private renderer: Renderer2,
        private metaService: MetaService,
        private currentRoute: ActivatedRoute,
        private router: Router
    ) {

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                let title = this.metaService.getRouteTitle();
                console.log('routing end event', title);

                if (title) {
                    this.metaService.setTitle(title);
                }
            })
    }

    ngOnInit() {
        this.renderer.listen(document, 'keydown.z', () => {
            alert('search employees');
        })
    }

    ngAfterContentChecked(): void {
        if (document.getElementsByClassName('abp-loader-bar is-loading').length)
            this.showLoader = true;
        else this.showLoader = false;
    }



}
