import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import 'hammerjs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { EmployeeProfileRoutingModule } from './employee-profile-routing.module';
import { EmployeeProfileComponent } from './employee-profile.component';
import { MainInterfaceComponent } from './components/main-interface/main-interface.component';
import { ProfileContentLeftComponent } from './components/profile-content-left/profile-content-left.component';
import { PersonalCardComponent } from './components/personal-card/personal-card.component';
import { ProfileContentRightComponent } from './components/profile-content-right/profile-content-right.component';
import { PersonalInfoTabComponent } from './components/personal-info-tab/personal-info-tab.component';
import { QualificationsTabComponent } from './components/qualifications-tab/qualifications-tab.component';
import { CertificationsTabComponent } from './components/certifications-tab/certifications-tab.component';
import { PositionCardComponent } from './components/position-card/position-card.component';
import { PositionInfoTabComponent } from './components/position-info-tab/position-info-tab.component';
import { HistoryTabComponent } from './components/history-tab/history-tab.component';
import { CompensationCardComponent } from './components/compensation-card/compensation-card.component';
import { CompensationAndBenifitsTabComponent } from './components/compensation-and-benifits-tab/compensation-and-benifits-tab.component';
import { DataWidgetComponent } from './components/data-widget/data-widget.component';
import { UnsubscriberComponent } from './components/unsubscriber/unsubscriber.component';
import { SharedModule } from '../shared/shared.module';
import { SalaryAnalysisChartComponent } from './components/salary-analysis-chart/salary-analysis-chart.component';
import { AttendanceTabComponent } from './components/attendance-tab/attendance-tab.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { EmployeeOrgchartComponent } from './components/employee-orgchart/employee-orgchart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { DefaultDirective } from './components/profile-content-right/default.directive';
import { SubTabDirective } from './components/sub-tab.directive';
import { TrainingsTabComponent } from './components/trainings-tab/trainings-tab.component';
import { CourseTableComponent } from './components/trainings-tab/course-table/course-table.component';

@NgModule({
    declarations: [
        EmployeeProfileComponent,
        MainInterfaceComponent,
        ProfileContentLeftComponent,
        PersonalCardComponent,
        ProfileContentRightComponent,
        PersonalInfoTabComponent,
        QualificationsTabComponent,
        CertificationsTabComponent,
        PositionCardComponent,
        PositionInfoTabComponent,
        HistoryTabComponent,
        CompensationCardComponent,
        CompensationAndBenifitsTabComponent,
        DataWidgetComponent,
        UnsubscriberComponent,
        SalaryAnalysisChartComponent,
        AttendanceTabComponent,
        EmployeeOrgchartComponent,
        SendMessageComponent,
        DefaultDirective,
        SubTabDirective,
        TrainingsTabComponent,
        CourseTableComponent,
    ],
    imports: [
        SharedModule,
        EmployeeProfileRoutingModule,
        ChartsModule,
        GridModule,
        ExcelModule,
        MatDialogModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class EmployeeProfileModule {}
