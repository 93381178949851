import { LocalizationService } from '@abp/ng.core';
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
    EmployeeProfileService,
    ProfileAllowancesDto,
    ProfileAllowancesPerYearDto,
    ProfilePerformanceDto,
    ProfileSalaryAndAppraisalDataDto,
} from '@proxy/intelligence/employee-profile';
import { NumberFormattingService } from 'src/app/shared/service/number-formatting.service';
import { DataService } from '../../services/data.service';

import { EmployeeDataService } from '../../services/employee-data.service';

@Component({
    selector: 'app-compensation-and-benifits-tab',
    templateUrl: './compensation-and-benifits-tab.component.html',
    styleUrls: ['./compensation-and-benifits-tab.component.scss'],
})
export class CompensationAndBenifitsTabComponent implements OnInit {
    EmployeeData = EmployeeDataService.ProfileData;
    salaryAppraisalData: ProfileSalaryAndAppraisalDataDto;
    performanceHistory: ProfilePerformanceDto[];
    lastestPerformance: ProfilePerformanceDto;
    allowancesPerYear: ProfileAllowancesPerYearDto[];
    profileAllowances: ProfileAllowancesDto[];
    basicSalaryPerYear: any;
    bonusPerYear: any;

    employeeId;
    @Input('employeeId') set employee(id: any) {
        if (id) {
            this.employeeId = id;
            this.getFunctionData(id);
        }
    }

    constructor(
        private dataService: DataService,
        private employeeProfileService: EmployeeProfileService,
        private localization: LocalizationService,
        private numberFormatting: NumberFormattingService
    ) { }
    ngOnInit(): void { }
    getFunctionData(id) {
        this.employeeProfileService.getProfileSalaryAndAppraisalDataByEmployeeId(id).subscribe(
            data => {
                this.salaryAppraisalData = data;
            },
            error => {
                console.log(error);
            }
        );

        this.employeeProfileService.getProfilePerformanceByEmployeeId(id).subscribe(d => {
            this.performanceHistory = d.sort((x, y) => x.year - y.year);

            this.lastestPerformance = this.performanceHistory[this.performanceHistory.length - 1];
        });

        this.employeeProfileService.getAllowancesPerYearByEmployeeId(id).subscribe(d => {
            this.allowancesPerYear = d;
        });

        this.employeeProfileService.getBonusPerYearByEmployeeId(id).subscribe(data => {
            this.bonusPerYear = data;
        });

        this.employeeProfileService.getProfileSalariesPerYearByEmployeeId(id).subscribe(data => {
            this.basicSalaryPerYear = data;
        });

        this.employeeProfileService.getEmpAllowancesByEmployeeId(id).subscribe(data => {
            this.profileAllowances = data;
        });
    }

    get lastBonusText() {
        if (this.salaryAppraisalData?.lastBonus == 0)
            return '-';

        return `${this.numberFormatting.formatCurrency(this.salaryAppraisalData?.lastBonus)} ${this.localize('For')} ${this.salaryAppraisalData?.lastBonusYear}`
    }

    get lastCommissionText() {
        if (this.salaryAppraisalData?.lastCommission == 0)
            return '-';

        return `${this.numberFormatting.formatCurrency(this.salaryAppraisalData?.lastCommission)} ${this.localize('For')} ${this.salaryAppraisalData?.lastCommisionYear}`
    }

    get enfOfServiceText() {
        if (this.salaryAppraisalData?.endOfService == 0)
            return '-'

        return `${this.numberFormatting.formatCurrency(this.salaryAppraisalData?.endOfService)} ${this.localize('as of today')} `
    }

    get lastIncrementText() {
        if (this.salaryAppraisalData?.lastIncrement == 0)
            return '-';

        return `${this.numberFormatting.formatCurrency(this.salaryAppraisalData?.lastIncrement)} ${this.localize('For')} ${this.salaryAppraisalData?.lastIncrementYear}`
    }

    localize(text: string) {
        return this.localization.instant(`EmployeeProfile::${text} `);
    }


}
