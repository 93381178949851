import type { CurrencyChangedDto, CurrencySelectorDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrencySelectorService {
  apiName = 'Default';

  currencyChangedByInput = (input: CurrencyChangedDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/intelligence/navigation/currency-selector/changed',
      body: input,
    },
    { apiName: this.apiName });

  get = () =>
    this.restService.request<any, CurrencySelectorDto[]>({
      method: 'GET',
      url: '/api/intelligence/navigation/currency-selector',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
