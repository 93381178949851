import { Component, OnInit } from '@angular/core';
import {
  EmpCertificationTrainingDto,
  EmployeeProfileService,
  ProfileDataDto,
} from '@proxy/intelligence/employee-profile';
import { combineLatest, Observable } from 'rxjs';
import { UnsubscriberComponent } from '../unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-data-widget',
  templateUrl: './data-widget.component.html',
  styleUrls: ['./data-widget.component.scss'],
})
export class DataWidgetComponent extends UnsubscriberComponent implements OnInit {
  protected dataToTrack: Observable<any>[];

  observableMap: {
    profileInfo: Observable<ProfileDataDto>;
    empCertificationTraining: Observable<EmpCertificationTrainingDto[]>;
  };

  constructor(protected employeeProfileService: EmployeeProfileService) {
    super();
    let employeeId = '100010';
    this.observableMap = {
      profileInfo: employeeProfileService.getProfileDataByEmployeeId(employeeId),
      empCertificationTraining: employeeProfileService.getEmpCertificationByEmployeeId(employeeId),
    };
  }

  ngOnInit(): void {
    if (this.dataToTrack.length > 0) {
      this.anotherSubscription = combineLatest(this.dataToTrack).subscribe(changedData => {
        this.onDataChange(changedData);
      });
    }
  }

  protected onDataChange(args: any[]): void {
    console.warn('Widget must implement onDataChange');
  }
}
