import { ContentChild, Directive, HostBinding, Input, Renderer2 } from '@angular/core';
import { of, pipe } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ZenTabsComponent } from 'src/app/shared/components/zen-tabs/zen-tabs.component';

@Directive({
    selector: 'zen-tabs[default]',
})
export class DefaultDirective {
    @Input('class') class = 'test test-x';

    @HostBinding('class')
    get classes(): string {
        return `${this.class}`;
    }

    constructor(private zenTabs: ZenTabsComponent) {
        setTimeout(() => {
            zenTabs.zenTabsContainer.nativeElement.classList.add(
                'flex',
                'flex-row',
                'gap-2',
                'z-10',
                'transform',
                'translate-y-px'
            );
            zenTabs.zenTabsContentContainer.nativeElement.classList.add(
                'flex',
                'flex-col',
                'h-full',
                'bg-white',
                'rounded-b-lg',
                'pb-7',
                'w-full',
                'items-stretch'
            );
        }, 0);
    }
}

// https://volosoft.com/Blog/attribute-directives-to-avoid-repetition-in-angular-templates
