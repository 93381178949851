import { Component } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'zen-range-input',
    templateUrl: './range-input.component.html',
    styleUrls: ['./range-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RangeInputComponent,
        },
    ],
})
export class RangeInputComponent implements ControlValueAccessor {
    showValue2: boolean = false;
    isDisabled: boolean = false;
    touched: boolean = false;

    operators: any[] = [
        { name: 'Greater than equal', symbol: '>=' },
        { name: 'Greater than', symbol: '>' },
        { name: 'Less than equal', symbol: '<=' },
        { name: 'Less than', symbol: '<' },
        { name: 'Equal', symbol: '=' },
        { name: 'Between', symbol: '><' },
    ];

    rangeForm = new FormGroup({
        operator: new FormControl(RangeInputOperators.Equal, [Validators.required]),
        value1: new FormControl('', [Validators.required]),
        value2: new FormControl('', [
            this.requiredIfValidator(
                () => this.rangeForm.get('operator').value == RangeInputOperators.Between
            ),
            this.booleanValidator(
                () =>
                    Number(this.rangeForm.get('value1').value) >
                    Number(this.rangeForm.get('value2').value)
            ),
        ]),
    });

    onChange(value) {}
    onTouched() {}

    constructor() {}

    ngOnInit(): void {
        this.rangeForm.valueChanges.pipe(debounceTime(200)).subscribe(x => {
            console.log(x);
            this.setValue();
        });
    }

    requiredIfBetweenInputValidator(formControl: AbstractControl) {
        if (!formControl.parent) {
            return null;
        }

        if (formControl.parent.get('operator').value == RangeInputOperators.Between) {
            return Validators.required(formControl);
        }

        return null;
    }

    booleanValidator(predicate) {
        return formControl => {
            if (!formControl.parent) {
                return null;
            }
            console.log('validation');
            const operator = formControl.parent.get('operator');
            if (operator != RangeInputOperators.Between) {
                return null;
            }

            var p = predicate();

            if (predicate()) {
                return { invalid: true };
            }

            return null;
        };
    }

    requiredIfValidator(predicate) {
        return formControl => {
            if (!formControl.parent) {
                return null;
            }
            console.log('validation');
            const operator = formControl.parent.get('operator').value;

            if (operator != RangeInputOperators.Between) {
                return null;
            }

            if (predicate()) {
                return Validators.required(formControl);
            }
            return null;
        };
    }

    writeValue(obj: any): void {
        console.log(obj);

        if (obj) {
            this.processValue(obj);
        }

        console.log(this.rangeForm.value);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    operatorChanged(event) {
        this.markAsTouched();
        if (event.value === '><') {
            this.enableValue2();
        } else {
            this.disableValue2();
        }
    }

    enableValue2() {
        this.showValue2 = true;
        // Enable required validation
    }

    disableValue2() {
        this.showValue2 = false;
        this.rangeForm.controls.value2.setValue('');
        // disable required validation
    }

    processValue(value: string) {
        if (value === null || value === '') {
            return;
        }

        const parts = value.split(',');

        this.operatorChanged(parts[0]);

        this.rangeForm.patchValue({
            operator: parts[0],
            value1: parts[1] || '',
            value2: parts[2] || '',
        });
    }

    setValue() {
        const value = this.rangeForm.value;
        let finalValue = '';

        if (
            (value.operator === RangeInputOperators.Between && value.value2 == '') ||
            !this.rangeForm.valid
        ) {
            return false;
        }

        if (value.operator === RangeInputOperators.Between) {
            finalValue = `${value.operator},${value.value1},${value.value2}`;
        } else {
            finalValue = `${value.operator},${value.value1}`;
        }

        console.log('final value', finalValue);
        this.onChange(finalValue);
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    onValueChange() {
        this.setValue();
    }
}

export enum RangeInputOperators {
    GreaterThan = '>',
    GreaterThanEqual = '>=',
    LessThan = '<',
    LessThanEqual = '<=',
    Between = '><',
    NotEqual = '!=',
    Equal = '==',
}
