import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ZenThemeModule } from 'zen-package';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalsService } from './global.service';
import { ClickOutsideDirective } from './click-outside.directive';
import { ZenSelectComponent } from './controls/zen-select/zen-select.component';
import { ZenModalComponent } from './components/zen-modal/zen-modal.component';
import { ZenDrawerComponent } from './components/zen-drawer/zen-drawer.component';
import { ZenRadioComponent } from './controls/zen-radio/zen-radio.component';
import { ZenToggleComponent } from './controls/zen-toggle/zen-toggle.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { ZenInputComponent } from './controls/zen-input/zen-input.component';
import { ZenMenuComponent } from './controls/zen-menu/zen-menu.component';
import { MenuItemDirective } from './directives/menu-item.directive';
import { ZenMenuTriggerDirective } from './directives/zen-menu-trigger.directive';
import { ZenDatepickerComponent } from './controls/zen-datepicker/zen-datepicker.component';
import { ZenTabsComponent } from './components/zen-tabs/zen-tabs.component';
import { ZenTabComponent } from './components/zen-tabs/zen-tab/zen-tab.component';
import { ZenSnackbarComponent } from './components/zen-snackbar/zen-snackbar.component';
import { SnackbarInstanceComponent } from './components/zen-snackbar/snackbar-instance/snackbar-instance.component';
import { ZenSnackbarDirective } from './directives/zen-snackbar.directive';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ZenTextareaComponent } from './controls/zen-textarea/zen-textarea.component';
import { EmptySectionComponent } from '../common-components/empty-section/empty-section.component';
import { SafePipe } from '../orgchart/pipes/safe.pipe';
@NgModule({
    imports: [
        CoreModule,
        ThemeSharedModule,
        ThemeBasicModule,
        NgbDropdownModule,
        ZenThemeModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
    ],
    exports: [
        CoreModule,
        ThemeSharedModule,
        ThemeBasicModule,
        NgbDropdownModule,
        ZenThemeModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        ZenSelectComponent,
        ClickOutsideDirective,
        ZenModalComponent,
        ZenDrawerComponent,
        ZenRadioComponent,
        ZenToggleComponent,
        RangeInputComponent,
        ZenInputComponent,
        ZenMenuComponent,
        MenuItemDirective,
        ZenMenuTriggerDirective,
        ZenDatepickerComponent,
        ZenTabComponent,
        ZenTabsComponent,
        ZenSnackbarComponent,
        ZenTextareaComponent,
        EmptySectionComponent,
        SafePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

    providers: [GlobalsService, CurrencyPipe, DecimalPipe],

    declarations: [
        ClickOutsideDirective,
        ZenSelectComponent,
        ZenModalComponent,
        ZenDrawerComponent,
        ZenRadioComponent,
        ZenToggleComponent,
        RangeInputComponent,
        ZenInputComponent,
        ZenMenuComponent,
        MenuItemDirective,
        ZenMenuTriggerDirective,
        ZenDatepickerComponent,
        ZenTabsComponent,
        ZenTabComponent,
        ZenSnackbarComponent,
        SnackbarInstanceComponent,
        ZenSnackbarDirective,
        ZenTextareaComponent,
        EmptySectionComponent,
        SafePipe
    ],
})
export class SharedModule { }
