import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomAccountService } from '@proxy/intelligence/accounts/custom-account.service';
import { ResetPasswordDto } from '@proxy/intelligence/accounts/models';
import { ConfigStateService } from '@abp/ng.core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  currentUser:any;
  constructor(private config: ConfigStateService,private formBuilder: FormBuilder,
    private customAccountService:CustomAccountService, private router: Router) { }
token:any;
  ngOnInit(): void {
    this.currentUser =this.config.getOne("currentUser");
    this.submitForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      oldPassword:['', Validators.required]
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
}
get f() { return this.submitForm.controls; }

onSubmit() {
  this.submitted = true;
  // stop here if form is invalid
  if (this.submitForm.invalid) {
      return;
  }

var request:ResetPasswordDto={
  userId:this.currentUser["id"],
  oldPassword:this.submitForm.controls["oldPassword"].value,
  newPassword:this.submitForm.controls["password"].value
}
this.customAccountService.resetPasswordByInput(request).subscribe((res:any)=>{
  console.log("resetPasswordByInput",res)
  this.router.navigate([''])
},(err:any)=>{
  console.log("resetPasswordByInput err",err)

})
}

}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
