import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProfileTabs } from '../profile-content-right/profile-content-right.component';

@Component({
    selector: 'app-personal-card',
    templateUrl: './personal-card.component.html',
    styleUrls: ['./personal-card.component.scss'],
})
export class PersonalCardComponent implements OnInit, AfterViewInit {
    @Input() employeeId;

    @ViewChild('personal') basicInfoTemplate;
    @ViewChild('qualifications') qualificationsTemplate;
    @ViewChild('certifications') certificationsTemplate;
    @ViewChild('trainings') trainingsTemplate;
    @ViewChild('title') titleTemplate;

    tabs = [] as ProfileTabs[];

    constructor(private localization: LocalizationService) {}
    ngAfterViewInit(): void {
        this.initializeTabs();
    }

    initializeTabs() {
        this.tabs = [
            {
                title: this.localization.instant('OrgChart::Basic Info'),
                contentTemplate: this.basicInfoTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
            {
                title: this.localization.instant('OrgChart::Qualifications'),
                contentTemplate: this.qualificationsTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
            {
                title: this.localization.instant('OrgChart::Certifications'),
                contentTemplate: this.certificationsTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
            {
                title: this.localization.instant('OrgChart::Trainings'),
                contentTemplate: this.trainingsTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
        ];
    }

    ngOnInit(): void {}
}
