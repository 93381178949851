import { SessionStateService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import {
    EmpCareerPathDto,
    EmpJobHistoryDto,
    EmployeeProfileService,
} from '@proxy/intelligence/employee-profile';

import { DataService } from '../../services/data.service';
import { EmployeeDataService } from '../../services/employee-data.service';

@Component({
    selector: 'app-history-tab',
    templateUrl: './history-tab.component.html',
    styleUrls: ['./history-tab.component.scss'],
})
export class HistoryTabComponent implements OnInit {
    jobHistory: EmpJobHistoryDto[];
    careerPaths: EmpCareerPathDto[];
    employeeId;
    @Input('employeeId') set employee(id: any) {
        if (id) {
            this.employeeId = id;
            this.employeeProfileService.getEmpJobHistory(id).subscribe(data => {
                this.jobHistory = data;
                console.log(data);
            });
            this.employeeProfileService.getEmpCareerPath(id).subscribe(data => {
                this.careerPaths = data;
                console.log(data);
            });
        }
    }

    constructor(
        private dataService: DataService,
        private employeeProfileService: EmployeeProfileService,
        public sessionState: SessionStateService
    ) {}

    ngOnInit(): void {}
}
