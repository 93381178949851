import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZenTabsComponent } from 'src/app/shared/components/zen-tabs/zen-tabs.component';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-main-interface',
    templateUrl: './main-interface.component.html',
    styleUrls: ['./main-interface.component.scss'],
})
export class MainInterfaceComponent implements OnInit {
    employeeId: string;
    bottomValue;
    sticky;
    @ViewChild('sidePanel') sidePanel: ElementRef;
    constructor(private route: ActivatedRoute, private dataService: DataService) {}
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        if (this.sidePanel.nativeElement.getBoundingClientRect().top <= 20) this.sticky = true;
        else this.sticky = false;
        let footerValues = document.querySelector<HTMLElement>('footer').getBoundingClientRect();
        if (window.innerHeight - (footerValues.bottom - footerValues.height) >= 0)
            this.bottomValue =
                window.innerHeight -
                (footerValues.bottom - footerValues.height) +
                footerValues.height / 4;
        else this.bottomValue = footerValues.height / 4;
    }
    ngOnInit(): void {
        this.employeeId = this.route.snapshot.url[0].path;
    }
}
