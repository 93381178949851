import { Component, Input, OnInit } from '@angular/core';
import { drawing, geometry, GradientStop, LinearGradient } from '@progress/kendo-drawing';
import { ChartComponent } from '@progress/kendo-angular-charts';
import {
    EmployeeProfileService,
    ProfileSalaryAnalysisResponseDto,
} from '@proxy/intelligence/employee-profile';
import { SubscriptionService } from '@abp/ng.core';

@Component({
    selector: 'app-salary-analysis-chart',
    templateUrl: './salary-analysis-chart.component.html',
    styleUrls: ['./salary-analysis-chart.component.scss'],
    providers: [SubscriptionService],
})
export class SalaryAnalysisChartComponent implements OnInit {
    majorGridLines: { color: string; visible: boolean }[];
    majorGridLinesY: { color: string; visible: boolean }[];
    majorGridLinesX: { color: string; visible: boolean }[];

    renderChart = false;

    @Input() employeeId;

    customData: any;

    public categoryPlotBands: any[];

    data: ProfileSalaryAnalysisResponseDto;

    constructor(
        private employeeProfileService: EmployeeProfileService,
        private subscription: SubscriptionService
    ) {}

    ngOnInit(): void {
        this.subscription.addOne(
            this.employeeProfileService.getProfileSalaryAnalysisByEmployeeId(this.employeeId),
            results => {
                this.data = results;
                this.processChartData();
                this.renderChart = true;
            }
        );
    }

    processChartData() {
        if (this.data) {
            if (this.data.series.length > 0) {
                let employeeData: any[] = [];

                for (let i = 0; i < this.data.series.length; i++) {
                    let empData = this.data.series[i];

                    employeeData.push({
                        value: Number(empData.basicSalary.toFixed(0)),
                        placement: i + 1,
                        // performanceColor:
                        //     '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
                        performanceColor: empData.performanceColor
                            ? empData.performanceColor
                            : '#' +
                              (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
                        empID: empData.employeeId,
                        nationality: empData.nationality,
                        jobGrade: empData.jobGradeName,
                        basicSalary: empData.basicSalary.toFixed(0),
                        jobStatus: empData.jobStatus,
                        payZone: empData.payZone,
                        fullName: empData.fullName,
                        jobTitle: empData.jobTitleName,
                        performanceRating: '3',
                        tenure: empData.tenure.toFixed(1) + ' Years',
                        joiningDate: '01 January 2018',
                        fromMed:
                            empData.differenceFromMedian.toFixed(0) +
                            ' (' +
                            empData.percentageFromMedian.toFixed(0) +
                            '%)',
                        fromMax:
                            empData.differenceFromMax.toFixed(0) +
                            ' (' +
                            empData.percentageFromMax.toFixed(0) +
                            '%)',
                        imgURL: '120002.jpg',
                        entities: empData.entitiesHierarchyList,
                        entity: empData.entityName,
                        //entityGroups: this.entityGroups,
                    });
                }

                this.majorGridLinesY = [
                    {
                        color: 'black',
                        visible: false,
                    },
                ];

                this.majorGridLinesX = [
                    {
                        color: 'black',
                        visible: false,
                    },
                ];

                this.majorGridLines = [
                    {
                        color: 'black',
                        visible: false,
                    },
                ];

                this.categoryPlotBands = [
                    {
                        from: this.data.min,
                        to: this.data.max,
                    },
                ];

                this.customData = employeeData;
                //this.categoryPlotBands = [{
                //  from: this.data.Min,
                //  to: this.data.Labels.find(l => l.Label == '0 %').Value,
                //  color: '#e64e4e',
                //  opacity: 0.2
                //}, {
                //  from: this.data.Labels.find(l => l.Label == '0 %').Value,
                //  to: this.data.Labels.find(l => l.Label == '100 %').Value,
                //  color: '#3eb75b',
                //  opacity: 0.2
                //}, {
                //  from: this.data.Labels.find(l => l.Label == '100 %').Value,
                //  to: this.data.Max,
                //  color: '#1678c2',
                //  opacity: 0.2
                //}];
            }
        }
    }

    public pointColor = (point): string => {
        // this is what i want to return ...
        console.log(point);
        return point.dataItem.performanceColor;
    };

    public labelContent = (e: any) => {
        // console.log('Value: ' +Math.floor(e.value));
        let lbl = this.data.labels.find(l => {
            let value = Number(e.value) + 2;
            let value2 = Number(e.value) - 2;
            let labelNumber = Number(l.value);
            if (labelNumber < value && labelNumber > value2) return true;
            else return false;
        });
        // console.log('Label: ' + Math.floor(lbl.Value));
        if (lbl) {
            let label = lbl.label;
            if (label == '0 %') {
                return 'Min \n' + this.numberWithCommas(e.value.toFixed(0));
            } else if (label == '50 %') {
                return 'Median \n' + this.numberWithCommas(e.value.toFixed(0));
            } else if (label == '100 %') {
                return 'Max \n' + this.numberWithCommas(e.value.toFixed(0));
            } else {
                return label + '\n' + this.numberWithCommas(e.value.toFixed(0));
            }
        } else {
        }
    };

    numberWithCommas(x) {
        if (Number(x) < 1000) return x.toString();
        return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    onSeriesClick(event) {}

    onRender(args: any) {
        const chart = args.sender;
        const geom = geometry;
        const draw = drawing;

        // get the axes
        const yAxis = chart.findAxisByName('yAxis');
        const xAxis = chart.findAxisByName('xAxis');

        // get the coordinates of the value at which the plot band will be rendered
        const valueRange = yAxis.range();
        const ySlot = yAxis.slot(valueRange.min, valueRange.max);

        // get the coordinates of the entire category axis range
        const range = xAxis.range();
        const xSlot = xAxis.slot(range.min, range.max);

        const difference = xAxis.options.majorUnit * this.data.additionalStep;

        const min = range.min + difference;
        const max = range.max - difference;

        const negativeSlot = xAxis.slot(range.min, min);
        const positiveSlot = xAxis.slot(max, range.max);
        const withinSlot = xAxis.slot(min, max);

        const rectBottomBelowSalaryScale = new geom.Rect(
            [xSlot.origin.x, xSlot.origin.y - 40],
            [negativeSlot.width(), 40 + ySlot.origin.y]
        );

        const rectBottomWithinSalaryScale = new geom.Rect(
            [xSlot.origin.x + negativeSlot.width(), xSlot.origin.y - 40],
            [withinSlot.width(), 40 + ySlot.origin.y]
        );

        const rectBottomBeyondSalaryScale = new geom.Rect(
            [xSlot.origin.x + negativeSlot.width() + withinSlot.width(), xSlot.origin.y - 40],
            [positiveSlot.width(), 40 + ySlot.origin.y]
        );

        const rectBelowSalaryScale = new geom.Rect(
            [xSlot.origin.x, ySlot.origin.y],
            [negativeSlot.width(), negativeSlot.origin.y]
        );

        const rectWithinSalaryScale = new geom.Rect(
            [xSlot.origin.x + negativeSlot.width(), ySlot.origin.y],
            [withinSlot.width(), withinSlot.origin.y]
        );

        const rectBeyondSalaryScale = new geom.Rect(
            [xSlot.origin.x + negativeSlot.width() + withinSlot.width(), ySlot.origin.y],
            [positiveSlot.width(), positiveSlot.origin.y]
        );

        const pathBelowSalaryScale = draw.Path.fromRect(rectBelowSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#FF4530', 0.15)],
            }),
        });

        const pathWithinSalaryScale = draw.Path.fromRect(rectWithinSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#36B37E', 0.15)],
            }),
        });

        const pathBeyondSalaryScale = draw.Path.fromRect(rectBeyondSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#007CDD', 0.15)],
            }),
        });

        const pathBottomBelowSalaryScale = draw.Path.fromRect(rectBottomBelowSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#FF4530', 1)],
            }),
        });

        const pathBottomWithinSalaryScale = draw.Path.fromRect(rectBottomWithinSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#36B37E', 1)],
            }),
        });

        const pathBottomBeyondSalaryScale = draw.Path.fromRect(rectBottomBeyondSalaryScale, {
            stroke: null,
            fill: new LinearGradient({
                stops: [new GradientStop(1, '#007CDD', 1)],
            }),
        });

        const group = new draw.Group();
        group.append(pathBelowSalaryScale, pathWithinSalaryScale, pathBeyondSalaryScale);

        const rectBottomGroup = new draw.Group();
        rectBottomGroup.append(
            pathBottomBelowSalaryScale,
            pathBottomWithinSalaryScale,
            pathBottomBeyondSalaryScale
        );

        chart.surface.draw(rectBottomGroup);
        chart.surface.draw(group);
    }

    tooltipContent(event) {
        const data = this.data.series[event.y - 1];

        return data;
    }
}
