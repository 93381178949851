import { Component, OnInit } from '@angular/core';
import { fadeUpDown } from 'src/app/shared/animations';

@Component({
  selector: 'zen-snackbar-instance',
  templateUrl: './snackbar-instance.component.html',
  styleUrls: ['./snackbar-instance.component.scss'],
  animations:[fadeUpDown]
})
export class SnackbarInstanceComponent implements OnInit {
  message: string = 'success';
  type: string = "info";
  delay:number;
  show: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, this.delay);
  }

}
