import { Component, EventEmitter, Renderer2, Input, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ChildrenAnimation, fadeUpDown } from '../../animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SessionStateService } from '@abp/ng.core';

@Component({
  selector: 'z-datepicker',
  templateUrl: './zen-datepicker.component.html',
  styleUrls: ['./zen-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenDatepickerComponent,
    },
  ],
  animations: [
    fadeUpDown, ChildrenAnimation
  ]
})
export class ZenDatepickerComponent implements ControlValueAccessor {

  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) { }
  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('body') body: ElementRef;
  @ViewChild('outerBody') outerBody: ElementRef;
  //local variables
  @Input() rtl: boolean = false;
  triggerWidth;
  open: boolean = false;
  dropDown: boolean = false;
  date: Date;
  innerOpen: boolean = false;
  tDate: any;
  minDate: Date;
  maxDate: Date;
  // months: any[] = []
  months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  years: any[] = [];
  days: any[] = [];
  month = 'October';
  year = '2003';
  monthList: boolean = false;
  yearList: boolean = false;
  mobileView: boolean = false;
  reverse: boolean = false;
  // scroll events variables
  // inputs
  scollOn: boolean = true;
  @Input() ignoreDays = [];
  @Input() language;
  @Input() shortDays = [];
  @Input('min') set givenMin(date: any) {
    if (date) {
      this.minDate = new Date(date);
    }
    else this.minDate = null;
  }
  @Input('max') set givenMax(date: any) {
    if (date) {
      this.maxDate = new Date(date);
    }
    else this.maxDate = null;
  }
  @Input() color = 'int';
  @Input() placeholder: any = 'DD/MM/YYYY';

  @Input() invalid = false;
  @Input() label = false;
  upper: boolean = false;
  goLeft: boolean = false;
  goRight: boolean = false;
  oppositeRight: boolean = false;
  oppositeLeft: boolean = false;
  // outputs

  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };
  writeValue(selected: any) {
    console.log(selected);
    if (selected !== undefined && new Date(selected)?.getTime()) {
      this.date = new Date(selected);
      this.tDate = new Date(selected);
    }
    else {
      console.log('triggered2');
      this.date = null;
      this.tDate = new Date();
    }


  }
  getMonthName(monthIndex: number) {
    return this.months[monthIndex]
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleChange(option) {
    this.onChange(option);
  }
  close() {
    this.innerOpen = false;

    setTimeout(() => {
      this.open = false;
      console.log('triggered');
      document.body.removeChild(this.outerBody.nativeElement)

    }, 300);
  }


  // functions
  initDateDrop(e?) {
    this.reverse = false;
    this.mobileView = false;
    this.oppositeRight = false;
    this.oppositeLeft = false;
    this.open = true;
    this.innerOpen = true;

    let xValue = this.trigger.nativeElement.getBoundingClientRect().left;
    let yValue = this.trigger.nativeElement.getBoundingClientRect().top;
    let triggerHeight = this.trigger.nativeElement.getBoundingClientRect().height;
    this.triggerWidth = this.trigger.nativeElement.getBoundingClientRect().width;
    console.log(this.trigger.nativeElement.getBoundingClientRect());

    this.cdr.detectChanges();

    document.body.appendChild(this.outerBody.nativeElement);
    document.body.clientWidth < 768 ? this.mobileView = true : this.mobileView = false;
    document.body.dir == 'rtl' ? this.rtl = true : this.rtl = false;
    yValue + 50 > window.innerHeight / 2 ? this.reverse = true : this.reverse = false;

    console.log(this.reverse);

    if (!this.mobileView)
      if (this.rtl) {
        if (xValue < 400)
          this.oppositeLeft = true;

        this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue + this.triggerWidth) + 'px, ' + (yValue + triggerHeight) + 'px)')
      }
      else {
        if (xValue > document.body.clientWidth - 400)
          this.oppositeRight = true;

        this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue) + 'px, ' + (yValue + triggerHeight) + 'px)');
      }
    this.yearList = this.monthList = false;
    this.getYearList();
    this.getWholeMonth(this.tDate);
  }

  getWholeMonth(date: Date) {
    this.days = [];
    let tDate: Date = new Date(date);
    let firstMonthDay: Date = new Date(tDate.setDate(tDate?.getDate() - date?.getDate() + 1));
    let firstSunday: Date = new Date(firstMonthDay.setDate(firstMonthDay.getDate() - firstMonthDay.getDay()));
    for (let index = 0; index < 35; index++) {

      this.days.push(new Date(firstSunday))
      firstSunday = new Date(firstSunday.setDate(new Date(firstSunday).getDate() + 1));
    }
  }
  getYearMonth(value, type) {
    this.yearList = this.monthList = false;

    let tDate = new Date(this.tDate);
    if (type == 'm') {
      tDate = new Date(tDate.setMonth(this.months.indexOf(value)));
    }
    if (type == 'y') {
      tDate = new Date(tDate.setFullYear(value));
    }
    this.tDate = tDate;
    this.getWholeMonth(this.tDate)
  }
  getYearList() {
    this.years = [];
    let min;
    let max;

    this.minDate?.getDate() ? min = this.minDate?.getFullYear() : min = this.tDate?.getFullYear() - 20;
    this.maxDate?.getDate() ? max = this.maxDate?.getFullYear() + 1 : max = this.tDate?.getFullYear() + 20;
    for (let index = 0; index < (max - min); index++) {
      this.years.push(min + index);

    }

  }

  clear(e?) {
    if (e)
      e.stopPropagation();
    this.date = null;
    this.tDate = new Date();
    this.open = false;
  }



  scroll(date, type) {
    let id;
    this.cdr.detectChanges();
    if (type == 'm')
      id = this.months[date.getMonth()];

    if (type == 'y')
      id = date.getFullYear();

    console.log(document.getElementById('a' + id.toString()));
    if (document.getElementById('a' + id.toString()))
      document.getElementById('a' + id.toString()).scrollIntoView({ behavior: 'auto', block: 'center' });
  }

  scrollControl(type, div) {
    console.log(type);

    if (type == 'y')
      if (div.scrollTop < 100 && !this.minDate) {
        let lastYear = this.years.slice()[0];
        for (let index = 1; index < 10; index++) {
          this.years.unshift(lastYear - index);
        }
      }
      else if (div.scrollTop > div.scrollHeight - 100 && !this.maxDate) {
        console.log(this.years.slice(-1)[0]);
        let lastYear = this.years.slice(-1)[0];
        for (let index = 1; index < 10; index++) {
          this.years.push(lastYear + index)
        }
      }
  }

  chosenDay(day) {
    this.date = this.tDate = day;
    this.open = false;
    this.onChange(day);
  }

  sameDay(one, two) {
    if (new Date(one)?.toDateString() === new Date(two)?.toDateString() && new Date(this.date)?.getMonth() === new Date(two)?.getMonth())
      return true;
    else return false;
  }
  todayCheck(date) {
    if (date?.toDateString() === new Date().toDateString())
      return true;
    else return false;
  }
  disabledDays(one, two) {
    let disable = false;
    // check if same day
    if (new Date(one)?.getMonth() !== new Date(two)?.getMonth()) // if the two dates not in the same month
      return disable = true;
    else if (new Date(one)?.getFullYear() !== new Date(two)?.getFullYear()) // same year check
      return disable = true;

    if (this.minDate instanceof Date && !isNaN(this.minDate.getTime())) {
      if (new Date(this.minDate.setHours(0, 0, 0, 0)).getTime() > new Date(new Date(two).setHours(0, 0, 0, 0)).getTime())
        return disable = true;
    }
    if (this.maxDate instanceof Date && !isNaN(this.maxDate.getTime())) {
      if (new Date(this.maxDate.setHours(0, 0, 0, 0)).getTime() < new Date(new Date(two).setHours(0, 0, 0, 0)).getTime())
        return disable = true;
    }

    return disable;
  }

  logSDate(one, two) {
    if (new Date(two).getTime() + 86400000 < new Date(this.minDate).getTime())
      console.log('its bigger date');

  }

  // othe functions


  logData(e) {
    console.log(e);

  }
  showValidation() {
    if (this.invalid && (!this.date?.getTime() || !this.tDate?.getTime()))
      return 'border-zen-red';
    else return 'border-transparent';
  }
}
