import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ZMenuTriggerFor]',
})
export class ZenMenuTriggerDirective {
  @Input() ZMenuTriggerFor;
  @Input() enableHover = false;
  constructor(private el: ElementRef) {

  }
  @HostListener('click') onClick() {
    this.initDrop();
  }

  @HostListener('mouseover') onMouseover() {
    if (this.enableHover) {
      this.initDrop();
      this.ZMenuTriggerFor.onHover = true;
    }
  }

  rtl: boolean = false;

  initDrop(e?) {
    if (document.body.dir == 'rtl')
      this.rtl = true;
    else this.rtl = false;

    this.ZMenuTriggerFor.open = true;
    setTimeout(() => {
      this.ZMenuTriggerFor.innerOpen = true;
    }, 100);
    let xValue = this.el.nativeElement.getBoundingClientRect().left;
    let yValue = this.el.nativeElement.getBoundingClientRect().top;
    let triggerHeight = this.el.nativeElement.getBoundingClientRect().height;
    this.ZMenuTriggerFor.triggerWidth = this.el.nativeElement.getBoundingClientRect().width;
    console.log(this.el.nativeElement.getBoundingClientRect());
    this.ZMenuTriggerFor.cdr.detectChanges();
    // check position
    document.body.appendChild(this.ZMenuTriggerFor.outerBody.nativeElement);
    document.body.clientWidth < 768 ? this.ZMenuTriggerFor.mobileView = true : this.ZMenuTriggerFor.mobileView = false;
    document.body.dir == 'rtl' ? this.rtl = true : this.rtl = false;
    yValue + 50 > window.innerHeight / 2 ? this.ZMenuTriggerFor.reverse = true : this.ZMenuTriggerFor.reverse = false;


    if (this.rtl) {
      if (xValue < 400)
        this.ZMenuTriggerFor.oppositeLeft = true;

      this.ZMenuTriggerFor.renderer.setStyle(this.ZMenuTriggerFor?.body.nativeElement, 'transform', 'translate(' + (xValue + this.ZMenuTriggerFor.triggerWidth) + 'px, ' + (yValue + triggerHeight) + 'px)')
    }
    else {
      if (xValue > document.body.clientWidth - 400)
        this.ZMenuTriggerFor.oppositeRight = true;

      this.ZMenuTriggerFor.renderer.setStyle(this.ZMenuTriggerFor?.body.nativeElement, 'transform', 'translate(' + (xValue) + 'px, ' + (yValue + triggerHeight) + 'px)');
    }

  }
}
