import { Injectable } from '@angular/core';

@Injectable()
export class GlobalsService {
  // color control for tables

  mainButton: string = 'orange';
  progress: string = 'bg-orange';
  white: string = 'white-bg';
  basic: string = 'bg-zen';
  completed: string = 'bg-zen-green';
  deleted: string = 'bg-zen-red';
  readonly hugeCount: number = 15000;
  readonly lgCount: number = 300;
  readonly mdCount: number = 100;
  readonly smCount: number = 20;

  readonly basicColors = { button: this.mainButton };
  readonly tableColors = {
    button: this.mainButton,
    STARTED: this.progress,
    COMPLETED: this.completed,
    DELETED: this.deleted,
    PENDING: this.basic,
    DRAFT: this.basic,
    LIVE: this.progress,
    CLOSED: this.completed,
  };

  constructor() {}
}
