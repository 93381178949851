import { SessionStateService } from '@abp/ng.core';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ChildrenAnimation, fadeUpDown } from '../../animations';

@Component({
    selector: 'z-menu',
    templateUrl: './zen-menu.component.html',
    styleUrls: ['./zen-menu.component.scss'],
    animations: [fadeUpDown, ChildrenAnimation],
})
export class ZenMenuComponent implements OnInit {
    onHover = false;
    constructor(
        public renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,

    ) {
        if (document.body.dir == 'rtl') {
            this.rtl = true;
        }
    }
    ngOnInit(): void {
    }
    @ViewChild('trigger') trigger: ElementRef;
    @ViewChild('body') body: ElementRef;
    @ViewChild('outerBody') outerBody: ElementRef;
    @ViewChild('inner') inner: ElementRef;
    open: boolean = false;
    innerOpen: boolean = false;
    opposite: boolean = false;
    rtl: boolean = false;
    searchValue: string;
    selected;
    list;
    oppositeRight: boolean = false;
    oppositeLeft: boolean = false;
    mobileView: boolean = false;
    reverse: boolean = false;
    tempList;
    triggerWidth;
    @Input() label;
    @Input('options') set ops(options) {
        this.list = options;
        this.tempList = options;
    }

    disable: boolean = false;
    onChange: any = () => { };
    onTouch: any = () => { };


    close() {
        this.innerOpen = false;
        setTimeout(() => {
            this.open = false;
            document.body.removeChild(this.outerBody.nativeElement);
        }, 300);
    }

    extractName(value) {
        return this.list?.find(v => v.value == value).name;
    }


}
