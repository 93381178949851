import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from '@progress/kendo-angular-charts';
import {
  EmployeeProfileService,
  ProfileDataDto,
} from '@proxy/intelligence/employee-profile';
import { combineLatest } from 'rxjs';
import { DataService } from '../../services/data.service';
import { EmployeeDataService } from '../../services/employee-data.service';

@Component({
  selector: 'app-personal-info-tab',
  templateUrl: './personal-info-tab.component.html',
  styleUrls: ['./personal-info-tab.component.scss'],
})
export class PersonalInfoTabComponent implements OnInit {
  profileData: ProfileDataDto;
  employeeId;
  @Input('employeeId') set employee (id:any){
    if(id){
    this.employeeId = id;
    this.employeeProfileService
    .getProfileDataByEmployeeId(id)
    .subscribe(data => {
      this.profileData = data;
    });
  }
  };
  
  constructor(
    private dataService: DataService,
    private employeeProfileService: EmployeeProfileService
  ) {}

  ngOnInit(): void {

  }
}
