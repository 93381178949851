import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { title } from 'process';

@Component({
    selector: 'app-profile-content-right',
    templateUrl: './profile-content-right.component.html',
    styleUrls: ['./profile-content-right.component.scss'],
})
export class ProfileContentRightComponent implements OnInit, AfterViewInit {
    @Input() employeeId;
    slide = 0;

    @ViewChild('title') titleTemplate;

    @ViewChild('personal') personalTemplate;
    @ViewChild('position') positionTemplate;
    @ViewChild('compensation') compensationTemplate;
    @ViewChild('attendance') attendanceTemplate;

    tabs = [] as ProfileTabs[];

    constructor(private localization: LocalizationService) {}
    ngAfterViewInit(): void {
        this.initializeTabs();
    }

    ngOnInit(): void {}

    initializeTabs() {
        this.tabs = [
            {
                title: this.localization.instant('OrgChart::Personal'),
                subMenu: [
                    {
                        title: 'Personal Info',
                        dataContext: { employeeId: this.employeeId },
                        titleTemplate: this.titleTemplate,
                        contentTemplate: this.positionTemplate,
                        subMenu: [],
                        icon: '',
                        isActive: false,
                    },
                    {
                        title: 'Qualifications',
                        dataContext: { employeeId: this.employeeId },
                        titleTemplate: this.titleTemplate,
                        contentTemplate: this.positionTemplate,
                        subMenu: [],
                        icon: '',
                        isActive: false,
                    },
                    {
                        title: 'Certifications',
                        dataContext: { employeeId: this.employeeId },
                        titleTemplate: this.titleTemplate,
                        contentTemplate: this.positionTemplate,
                        subMenu: [],
                        icon: '',
                        isActive: false,
                    },
                ],
                dataContext: { employeeId: this.employeeId },
                contentTemplate: this.personalTemplate,
                titleTemplate: this.titleTemplate,
                icon: 'fa-light fa-user-alt',
                isActive: false,
            },
            {
                title: this.localization.instant('OrgChart::Position'),
                dataContext: { employeeId: this.employeeId },
                titleTemplate: this.titleTemplate,
                contentTemplate: this.positionTemplate,
                subMenu: [],
                icon: 'fa-light fa-briefcase',
                isActive: false,
            },
            {
                title: this.localization.instant('OrgChart::Financial'),
                dataContext: { employeeId: this.employeeId },
                titleTemplate: this.titleTemplate,
                contentTemplate: this.compensationTemplate,
                subMenu: [],
                icon: 'fa-light fa-coins',
                isActive: false,
            },
            {
                title: this.localization.instant('OrgChart::Attendance'),
                dataContext: { employeeId: this.employeeId },
                titleTemplate: this.titleTemplate,
                contentTemplate: this.attendanceTemplate,
                subMenu: [],
                icon: 'fa-light fa-calendar',
                isActive: false,
            },
        ];
    }
}

export interface ProfileTabs {
    title: string;
    titleTemplate: any;
    contentTemplate: any;
    subMenu: ProfileTabs[];
    dataContext: any;
    icon: any;
    isActive: boolean;
}
