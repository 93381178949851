import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { EmployeeProfileService, ProfileDataDto } from '@proxy/intelligence/employee-profile';
import { DataService } from '../../services/data.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendMessageComponent } from '../send-message/send-message.component';
import { SessionStateService } from '@abp/ng.core';
import { OrgchartNodeService } from 'src/app/orgchart/services/orgchart-node.service';
import { MetaService } from 'src/app/shared/services/meta.service';

@Component({
    selector: 'app-profile-content-left',
    templateUrl: './profile-content-left.component.html',
    styleUrls: ['./profile-content-left.component.scss'],
})
export class ProfileContentLeftComponent implements OnInit {
    @ViewChild('performanceChart', { read: ChartComponent })
    performanceChart!: ChartComponent;

    performancePerYear: any;

    _employeeId: string;
    @Input() set employeeId(employeeId: string) {
        this._employeeId = employeeId;

        if (employeeId) this.getEmployeeData(employeeId);
    }

    max = 0;
    employeeData;

    constructor(
        private employeeProfileService: EmployeeProfileService,
        private dataService: DataService,
        private dialog: MatDialog,
        public sessionService: SessionStateService,
        private metaService: MetaService
    ) { }

    ngOnInit(): void { }

    getEmployeeData(employeeId) {
        this.employeeProfileService.getProfileDataByEmployeeId(employeeId).subscribe(data => {
            const initials = OrgchartNodeService.GetNameInitals(data.firstName, data.lastName);
            this.metaService.setTitle(`${this.metaService.getRouteTitle()} | ${data.firstName} ${data.lastName}`)
            data['initials'] = initials;
            this.employeeData = data;
        });

        this.employeeProfileService
            .getProfilePerformanceByEmployeeId(employeeId)
            .subscribe(data => {
                this.performancePerYear = data.sort((x, y) => x.year - y.year);
            });
    }

    openCompareModal(event) { }

    openSendMessageModal(event) {
        this.dialog.open(SendMessageComponent, {
            data: {
                animal: 'panda',
            },
        });
    }
}
