import {
    ConfigStateService,
    Environment,
    EnvironmentService,
    LanguageInfo,
    SessionStateService,
    SubscriptionService,
} from '@abp/ng.core';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoaderService } from './../../shared/loader.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    CurrencySelectorService,
    UserNavigationEntitiesService,
} from '@proxy/intelligence/navigation';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations';
import {
    ActivationStart,
    Router,
} from '@angular/router';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [ChildrenAnimation, fadeUpDown],
})
export class HeaderComponent implements OnInit {
    currentUser: any;
    currentTenant: any;
    private environment: Environment;



    constructor(
        private cdr: ChangeDetectorRef,
        private configState: ConfigStateService,
        public loaderService: LoaderService,
        private currencySelectorService: CurrencySelectorService,
        private userNavigationService: UserNavigationEntitiesService,
        private subscription: SubscriptionService,
        public sessionState: SessionStateService,
        private router: Router,
        private environmentService: EnvironmentService
    ) {
        router.events
            .pipe(filter(event => event instanceof ActivationStart))
            .subscribe((event: ActivationStart) => {
                this.title = event.snapshot.data.name;
            });

        this.environment = this.environmentService.getEnvironment();
    }
    title: string;
    lang: string;
    language;
    selectedEntity;
    customLogo: boolean = false;
    showMenu: boolean = false;
    showUser: boolean = false;
    showNotifications: boolean = false;
    notificationMessages = [{ title: 'test message', message: 'hey notifications are working!' }];

    closeDropdown(menu) {
        if (this[menu]) this[menu] = false;
    }
    header = 'header';
    currencies;
    currentCurrency;
    selectedCurrency;
    showLoader;
    languages$: Observable<LanguageInfo[]> = this.configState.getDeep$('localization.languages');

    get changePasswordUrl() {
        return `${this.environment.oAuthConfig.issuer}/Account/Manage`;
    }

    get smallScreen(): boolean {
        return window.innerWidth < 992;
    }

    get defaultLanguage$(): Observable<string> {
        return this.languages$.pipe(
            map(
                (languages: LanguageInfo[]) =>
                    languages?.find(lang => lang.cultureName === this.selectedLangCulture)
                        .displayName || ''
            )
        );
    }

    get dropdownLanguages$(): Observable<LanguageInfo[]> {
        return this.languages$.pipe(
            map(
                (languages: LanguageInfo[]) =>
                    languages?.filter(lang => lang.cultureName !== this.selectedLangCulture) || []
            )
        );
    }
    logData(data) {
        console.log(data);
    }

    get selectedLangCulture(): string {
        return this.sessionState.getLanguage();
    }

    ngOnInit() {
        this.currentUser = this.configState.getOne('currentUser');
        this.currentTenant = this.configState.getOne('currentTenant');

        this.refreshCurrency();
        console.log(this.configState.getAll());
    }

    fetchCurrencies() {
        this.subscription.addOne(this.currencySelectorService.get(), (result) => {
            this.initializeCurrencies(result);
            localStorage.setItem('currencies', JSON.stringify(result));
        })
    }

    refreshCurrency() {
        let currencies = JSON.parse(localStorage.getItem('currencies'));

        if (!currencies) {
            this.fetchCurrencies();
            return;
        }

        this.initializeCurrencies(currencies);
    }

    initializeCurrencies(result) {
        this.currentCurrency = result.find(s => s.isSelected);
        this.selectedCurrency = this.currentCurrency?.code;
        this.currencies = result;
    }

    changeCurrency(currency) {
        let cur = { code: currency };
        this.currencySelectorService.currencyChangedByInput(cur).subscribe(
            res => {
                //location.reload();
                //this.refreshCurrency();
            },
            err => { }
        );
    }

    ngAfterContentChecked(): void {
        if (document.getElementsByClassName('abp-loader-bar is-loading').length)
            this.showLoader = true;
        else this.showLoader = false;
    }

    onChangeLang(cultureName: string) {
        this.sessionState.setLanguage(cultureName);
    }

    onEntityClicked(node) {
        console.log(node);
        this.selectedEntity = node;
        localStorage.setItem('currentEntity', JSON.stringify(node));
        this.subscription.addOne(this.userNavigationService.setSelectedEntityByInput(node), () => {
            location.reload();
        });
    }

    get currentEntity() {
        return JSON.parse(localStorage.getItem('currentEntity'));
    }

    get userName() {
        return this.currentUser.name;
    }

    get tenantName() {
        return this.currentTenant.name;
    }

    get userFullName() {
        return `${this.currentUser.name} ${this.currentUser.surName}`;
    }

    tempList;
    surveys: any;
    displayOptions: boolean = false;
    tLanguage;
    tEntity;

    openOptions() {
        this.tEntity = this.selectedEntity;
        this.tLanguage = this.sessionState.getLanguage();
        this.displayOptions = true;
    }

    displayChanges() {
        this.displayOptions = false;
        if (this.tLanguage != this.language) {
            this.language = this.tLanguage;
            this.onChangeLang(this.tLanguage);
        }
        if (this.selectedCurrency != this.currentCurrency?.code) {
            this.changeCurrency(this.selectedCurrency);
        }
        if (this.tEntity != this.selectedEntity)
            setTimeout(() => {
                this.selectedEntity = this.tEntity;
                this.onEntityClicked(this.tEntity);
            }, 1000);


    }
}
