import { Component, Input, OnInit } from '@angular/core';
import {
    EmployeePostionInfoDto,
    EmployeeProfileService,
} from '@proxy/intelligence/employee-profile';
import { DataService } from '../../services/data.service';
import { EmployeeDataService } from '../../services/employee-data.service';

@Component({
    selector: 'app-position-info-tab',
    templateUrl: './position-info-tab.component.html',
    styleUrls: ['./position-info-tab.component.scss'],
})
export class PositionInfoTabComponent implements OnInit {
    positionInfo: EmployeePostionInfoDto;
    employeeId;
    @Input('employeeId') set employee(id: any) {
        if (id) {
            this.employeeId = id;
            this.employeeProfileService.getEmployeePositionInfo(this.employeeId).subscribe(data => {
                this.positionInfo = data;
            });
        }
    }

    constructor(
        private dataService: DataService,
        private employeeProfileService: EmployeeProfileService
    ) {}
    EmployeeData = EmployeeDataService.ProfileData;
    EntitiesHierarchy = JSON.parse(this.EmployeeData.EntitiesHierarchy).reverse();

    ngOnInit(): void {}

    convertStringToArray(json) {
        return JSON.parse(json);
    }
}
