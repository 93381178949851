import { ConfigStateService, CurrentUserDto } from '@abp/ng.core';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import OrgChart from '@balkangraph/orgchart.js/orgchart';
import {
    EmployeeProfileService,
    EmpPositionOrgChartDto,
} from '@proxy/intelligence/employee-profile';
import { eOrgchartTemplate } from 'src/app/orgchart/models/e-orgchart-template.enum';
import { OrgchartNodeService } from 'src/app/orgchart/services/orgchart-node.service';
import { OrgchartTemplateService } from 'src/app/orgchart/services/orgchart-template.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-employee-orgchart',
    templateUrl: './employee-orgchart.component.html',
    styleUrls: ['./employee-orgchart.component.scss'],
})
export class EmployeeOrgchartComponent implements OnInit, AfterViewInit {
    @ViewChild('tree', { read: ElementRef }) tree: ElementRef;
    chart: OrgChart = null;
    nodes: EmpPositionOrgChartDto[];

    chartConfig: any = {
        enableSearch: false,
        template: eOrgchartTemplate.Default,
        nodeMouseClick: OrgChart.action.none,
        scaleInitial: 1.5,
        collapse: {
            level: 2,
            allChildren: true,
        },
        nodeBinding: {
            employeeId: 'id',
            name: 'fullName',
            jobTitle: 'jobTitle',
            department: 'entityName',
            img_0: this.orgchartImageHandler,
        },
    };

    _employeeId: string;
    @Input() set employeeId(employeeId: string) {
        this._employeeId = employeeId;
        if (this._employeeId) {
            this.employeeProfileService.getEmpPositionOrgChart(this._employeeId).subscribe(data => {
                data.forEach(employee => {
                    const name = employee.fullName.split(' ');
                    employee['initials'] = OrgchartNodeService.GetNameInitals(
                        name[0],
                        name[name.length - 1]
                    );
                });
                this.nodes = data;

                setTimeout(() => {
                    this.initializeOrgChart();
                }, 1000);
            });
        }
    }

    constructor(
        private dataService: DataService,
        private employeeProfileService: EmployeeProfileService,
        private config: ConfigStateService,
        private orgchartTemplateSerivce: OrgchartTemplateService
    ) { }
    ngOnInit(): void { }

    ngAfterViewInit(): void { }

    initializeOrgChart() {
        this.chart = new OrgChart(this.tree.nativeElement, this.chartConfig);
        this.chart.load(this.nodes);
    }

    orgchartImageHandler(sender, node) {
        const data = sender.get(node.id);

        if (!data) {
            return null;
        }
        if (!data.imgURL && !data.initials) {
            return null;
        }
        const imagePath = data.imgURL;
        const initials = data.initials;

        const width = node.templateName === eOrgchartTemplate.Default ? 70 : 80;
        const height = node.templateName === eOrgchartTemplate.Default ? 90 : 110;

        return `
        <foreignobject width="${width}" height="${height}" rx="10" ry="10" x="20" y="20" fill="none" stroke-width="2" stroke="url(#linear)" style="border-radius: 4px 4px 4px 4px;outline:3px solid rgba(88, 111, 153, 0.3); outline-offset:-3px;">
            <div class="org-employee-photo relative" style="height:100%" data-initials="${initials}">
            <div class="h-full w-full absolute flex flex-col justify-center items-center text-xl" >  ${initials} </div>
                <img style="outline: 4px solid rgba(70, 89, 122, 0.3);outline-offset: -2px;" class="h-full w-full absolute object-cover" src="${imagePath}" data-initials="${initials}" onloadend="this.classList.remove('image-loading')"  onloadstart="this.classList.add('image-loading')" onerror="this.classList.add('error-img')" width="100%" height="100%" style="border-radius: 4px;">
            </div>
        </foreignobject>
            `;
    }
}
