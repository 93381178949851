import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './common-components/reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgetPasswordComponent } from './common-components/forget-password/forget-password.component';
import { AuthGuard } from '@abp/ng.core';

const routes: Routes = [

    {
        path: '',
        pathMatch: 'full',
        redirectTo: "report-builder",
        canActivate: [AuthGuard]
    },
    {
        path: 'org-chart',
        data: { name: 'Orgchart' },
        loadChildren: () => import('./orgchart/orgchart.module').then(m => m.OrgchartModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'report-builder',
        data: { name: 'Report Builder' },
        loadChildren: () =>
            import('./report-builder/report-builder.module').then(m => m.ReportBuilderModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'employee-profile',
        data: { name: 'Employee Profile' },
        loadChildren: () =>
            import('./employee-profile/employee-profile.module').then(m => m.EmployeeProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'control-panel',
        loadChildren: () =>
            import('./control-panel/control-panel.module').then(m => m.ControlPanelModule),
        canActivate: [AuthGuard]
    },
    { path: 'logout', component: LogoutComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },

    {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'workforce',
        data: { name: 'Workforce' },
        loadChildren: () => import('./workforce/workforce.module').then(m => m.WorkforceModule),
        canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
