import { SessionStateService } from '@abp/ng.core';
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { stringify } from 'querystring';

@Directive({
  selector: '[zMenuItem]'
})
export class MenuItemDirective {
  @HostBinding('class') className = this.returnClass(this.el);
  dir;
  constructor( private el: ElementRef) {
  
  }
 
  
  returnClass(el:ElementRef) { 
    if (document.body.dir == 'rtl') {
      this.dir = 'rtl';
    }
    let className: string = "flex flex-row items-center gap-2 text-sm leading-8 btn rounded hover:bg-opacity-20 cursor-pointer whitespace-nowrap", extendedClass;
    if (el.nativeElement.getAttribute('delete') != null)
      extendedClass = ' text-zen-red hover:bg-zen-red';
    else  if (el.nativeElement.getAttribute('success') != null)
      extendedClass = ' text-zen-green hover:bg-zen-green';
    else extendedClass = ' text-zen hover:bg-int';
     
    if(extendedClass)
    return className.concat(extendedClass.concat(this.dir == 'rtl' ? ' pl-6 pr-4' : ' pr-6 pl-4'));
  }
}



