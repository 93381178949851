import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProfileTabs } from '../profile-content-right/profile-content-right.component';

@Component({
    selector: 'app-position-card',
    templateUrl: './position-card.component.html',
    styleUrls: ['./position-card.component.scss'],
})
export class PositionCardComponent implements OnInit, AfterViewInit {
    @Input() employeeId;

    @ViewChild('position') positionTemplate;
    @ViewChild('orgchart') orgchartTemplate;
    @ViewChild('history') historyTemplate;

    @ViewChild('title') titleTemplate;

    tabs = [] as ProfileTabs[];

    constructor(private localization: LocalizationService) { }

    ngAfterViewInit(): void {
        this.initializeTabs();
    }

    initializeTabs() {
        this.tabs = [
            {
                title: this.localization.instant('OrgChart::Position Info'),
                contentTemplate: this.positionTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
            {
                title: this.localization.instant('OrgChart::OrgChart'),
                contentTemplate: this.orgchartTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
            {
                title: this.localization.instant('OrgChart::History'),
                contentTemplate: this.historyTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false
            },
        ];
    }
    ngOnInit(): void {
    }
}
