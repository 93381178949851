import {
    AfterContentInit,
    AfterViewChecked,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ContentChildren,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { delay } from 'rxjs/operators';
import { ZenTabComponent } from './zen-tab/zen-tab.component';

@Component({
    selector: 'zen-tabs',
    templateUrl: './zen-tabs.component.html',
    styleUrls: ['./zen-tabs.component.scss'],
})
export class ZenTabsComponent implements OnInit, AfterContentInit {
    @ContentChildren(ZenTabComponent) tabs: QueryList<ZenTabComponent>;

    @ViewChild('container', { read: ViewContainerRef, static: false })
    dynamicTabPlaceholder: ViewContainerRef;
    @ViewChild('zenTabs', { read: ElementRef, static: false }) zenTabsContainer: ElementRef;
    @ViewChild('zenContent', { read: ElementRef, static: false })
    zenTabsContentContainer: ElementRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngAfterContentInit(): void {
        //For Static Tabs
        this.setActiveTabOnInitialization();

        //For Dynamic Tabs (ngFor).
        //Delay : https://stackoverflow.com/questions/55286309/changing-contentchildren-models-on-querylist-changes
        this.tabs.changes.pipe(delay(0)).subscribe(tabs => {
            this.setActiveTabOnInitialization();
        });
    }

    setActiveTabOnInitialization() {
        if (this.tabs.length === 0) {
            return;
        }

        const activeTabs = this.tabs.filter(tab => tab.active);

        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }

    ngOnInit(): void {}

    openTab(title, template, data, isCloseable = false) {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(ZenTabComponent);

        const componentRef = this.dynamicTabPlaceholder.createComponent(componentFactory);
        const instance: ZenTabComponent = componentRef.instance as ZenTabComponent;

        instance.tabTitle = title;
        instance.dataContext = data;
        instance.template = template;

        this.tabs.reset([...this.tabs.toArray(), instance]);
    }

    selectTab(tab: ZenTabComponent) {
        this.tabs.toArray().forEach(tab => {
            tab.active = false;
        });

        tab.active = true;
    }
}
