import { Injector } from "@angular/core";
import { CurrencySelectorService } from "@proxy/intelligence/navigation";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';

export function initializeAppFactory(
    injector: Injector
): () => Observable<any> {
    const currencySelectorService = injector.get(CurrencySelectorService);
    return () =>
        currencySelectorService
            .get()
            .pipe(tap(x => localStorage.setItem('currencies', JSON.stringify(x))));
}

export function initializeDefaultCurrencyCode() {
    const currencies = JSON.parse(localStorage.getItem('currencies'));
    let code = 'USD';

    currencies.forEach(x => {
        if (x.isSelected === true) {
            code = x.code;
        }
    });

    return `${code} `;
}