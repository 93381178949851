import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'z-modal',
  templateUrl: './zen-modal.component.html',
  styleUrls: ['./zen-modal.component.scss'],
  animations: [trigger('inOutAnimation', [
    transition(':enter', [
      style({ transform: 'scale(1.05)', opacity: 0, filter: 'blur(6px)' }),
      animate('.2s ease-in', style({ transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' })),
    ]),
    transition(':leave', [
      style({
        transform: 'scale(1) translateY(0px)',
        opacity: 1,
        filter: 'blur(0px)',
      }),
      animate(
        '.2s ease-in',
        style({ transform: 'scale(.95)', opacity: 0, filter: 'blur(6px)' })
      ),
    ]),
  ])]
})
export class ZenModalComponent implements OnInit {

  @Input() autoClose: boolean = false;
  @Input() open: boolean = false;
  @Output() openChange = new EventEmitter();

  ngOnInit(): void {
  }

  close(){
    if(!this.autoClose)
    this.open = false;
    setTimeout(() => {
      this.openChange.emit(false);
    }, 300);
  }

}
