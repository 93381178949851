import {
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'z-input',
  templateUrl: './zen-input.component.html',
  styleUrls: ['./zen-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenInputComponent,
    },
  ],
})
export class ZenInputComponent implements ControlValueAccessor {

  constructor() { }



  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  @Input() email: boolean = false;
  @Input() required: boolean = false;
  @Input() number: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label;
  @Input() placeholder;
  @Input() maxCount
  selected;
  isDisabled: boolean = false;
  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(selected: any) {
    if (selected !== undefined && this.selected !== selected) {
      this.selected = selected;
    }
  }

  registerOnChange(fn: any) {

    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  handleChange(option) {
    this.selected = option;
    this.onChange(option);
  }
  change(e) {
    if (this.number) {
      const initalValue = e.target.value;
      e.target.value = initalValue.replace(/[^0-9]*/g, '');

      if (initalValue !== e.target.value)
        e.stopPropagation();

    }
  }

}

