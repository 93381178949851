import { Environment } from '@abp/ng.core';

const baseUrl = 'https://orgchart.zenithr.net';

export const environment = {
    production: false,
    application: {
        baseUrl,
        name: 'orgchart',
        logoUrl: '',
    },
    oAuthConfig: {
        //issuer: 'https://localhost:44374',
        issuer: 'https://id.zenithr.net',
        redirectUri: baseUrl,
        clientId: 'angular-orgchart-client',
        responseType: 'code',
        showDebugInformation: true,
        scope: 'openid profile PublicWebSiteGateway ProductManagementService IntelligenceService ClientManagementService',
        requireHttps: true,
    },
    apis: {
        default: {
            url: 'https://zen-api.zenithr.net',
            //url: 'https://localhost:44356',
            // https://localhost:44374
            rootNamespace: 'Zenithr',
        },
        frontend: {
            url: baseUrl,
        }
    },
    localization: {
        defaultResourceName: 'Intelligence',
    },
} as Environment;
