import type { UserNavigationEntityDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserNavigationEntitiesService {
  apiName = 'Default';

  get = () =>
    this.restService.request<any, UserNavigationEntityDto[]>({
      method: 'GET',
      url: '/api/intelligence/navigation/entities-tree',
    },
    { apiName: this.apiName });

  setSelectedEntityByInput = (input: UserNavigationEntityDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/intelligence/navigation/entities-tree/set-current-entity',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
