import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unsubscriber',
  templateUrl: './unsubscriber.component.html',
  styleUrls: ['./unsubscriber.component.scss'],
})
export class UnsubscriberComponent implements OnDestroy {
  private _subscription: Subscription = new Subscription();

  constructor() {}
  ngOnDestroy(): void {
    if (this._subscription) this._subscription.unsubscribe();
  }

  protected resetSubscriptions() {
    if (this._subscription) this._subscription.unsubscribe();
  }

  addSubscription(sub: Subscription) {
    this._subscription.add(sub);
  }

  set anotherSubscription(sub: Subscription) {
    this._subscription.add(sub);
  }
}
