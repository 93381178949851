import { SessionStateService, SubscriptionService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { EmpCertificationTrainingDto, EmployeeProfileService } from '@proxy/intelligence/employee-profile';
import { DataService } from '../../services/data.service';
import { DataWidgetComponent } from '../data-widget/data-widget.component';
@Component({
  selector: 'app-certifications-tab',
  templateUrl: './certifications-tab.component.html',
  styleUrls: ['./certifications-tab.component.scss'],
})
export class CertificationsTabComponent implements OnInit {

  employeeCertifications: EmpCertificationTrainingDto[];

  employeeId;
  @Input('employeeId') set employee(id: any) {
    if (id) {
      this.employeeId = id;
      this.employeeProfileService
      .getEmpCertificationByEmployeeId(id)
      .subscribe(certifications => {
        this.employeeCertifications = certifications;
        console.log(certifications);
        
      });
  }
  };

  constructor(
    private employeeProfileService: EmployeeProfileService,
    private dataService: DataService,
    public sessionState: SessionStateService,
  ) {}

  ngOnInit(): void {}
}
