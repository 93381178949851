import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ZenSnackbar]'
})
export class ZenSnackbarDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }


}
