import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss']
})
export class EmptySectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
