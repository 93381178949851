import { SubscriptionService } from '@abp/ng.core';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ZenSnackbarService } from '../../services/zen-snackbar.service';
import { SnackbarInstanceComponent } from './snackbar-instance/snackbar-instance.component';

@Component({
  selector: 'zen-snackbar',
  templateUrl: './zen-snackbar.component.html',
  styleUrls: ['./zen-snackbar.component.scss']
})
export class ZenSnackbarComponent implements OnInit {
  @ViewChild('vc', {read:ViewContainerRef, static:true}, ) vc: ViewContainerRef;
  constructor(private resolver: ComponentFactoryResolver, private zenSnackbar: ZenSnackbarService, private cdr: ChangeDetectorRef, private subscription: SubscriptionService) {

  }
  delay:number = 1500;
  show:boolean = false;
  ngOnInit(): void {
    this.subscription.addOne(this.zenSnackbar.getSnackBar$,(data=>{
      if(data)
      this.loadDynamicComponent(data);
    }))
  }
  loadDynamicComponent(data) {
    const factory = this.resolver.resolveComponentFactory(SnackbarInstanceComponent);
   let component =  this.vc.createComponent(factory);
    component.instance.message = data.message;
    component.instance.type = data.type;
    component.instance.delay = (data.delay?data.delay: this.delay);
    setTimeout(() => {
      this.vc.remove();
    }, this.delay + 300);
  }
}
