import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { EmployeeProfileService } from '@proxy/intelligence/employee-profile';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-attendance-tab',
    templateUrl: './attendance-tab.component.html',
    styleUrls: ['./attendance-tab.component.scss'],
})
export class AttendanceTabComponent implements OnInit {
    attendanceAnalysis: any;
    utilizationByMonth: any;
    options: any;
    public pageSize = 10;
    public skip = 0;

    @Input() employeeId;

    constructor(
        private dataService: DataService,
        private employeeProfileService: EmployeeProfileService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.employeeProfileService
            .getEmployeeAttendanceAnalysisData(
                this.employeeId,
                new Date().getFullYear().toString(),
                '4'
            )
            .subscribe(data => {
                this.renderTable(data);
            });

        this.employeeProfileService
            .getEmployeeUtilizationByMonth(this.employeeId, new Date().getFullYear().toString())
            .subscribe(data => {
                this.utilizationByMonth = data;
            });
    }

    localize(text) {
        return this.localizationService.instant(`EmployeeProfile::${text}`);
    }

    renderTable(data) {
        let columns = [{
            field: 'date',
            title: this.localize("Date")
        }, {
            field: 'checkIn',
            title: this.localize("Check In")
        },
        {
            field: 'checkOut',
            title: this.localize("Check Out")
        }, {
            field: 'attendedHours',
            title: this.localize("Work Hours")
        }
        ];

        this.options = {
            data: data,
            columns: columns,
            searchText: this.localize("search..."),
            export: true,
            exportName: 'export file name',
            exportText: this.localize("Export"),
            colors: {
                button: 'int'
            }
        }
    }
}
