import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZenSnackbarService {

  private subject = new Subject<any>();

  snackBar(message: ZenSnackBarOptions) {
    this.subject.next(message);
  }

  clearSnackBars() {
    this.subject.next(null);
  }

  getSnackBar$ = this.subject.asObservable();
}

export interface ZenSnackBarOptions {
  message: string;
  delay?: number;
  type: ZenSnackBarTypes;
}

export enum ZenSnackBarTypes {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}
