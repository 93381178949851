import type { EmpCareerPathDto, EmpCertificationTrainingDto, EmpEducationDto, EmpJobHistoryDto, EmpPositionOrgChartDto, EmployeeAttendanceAnalysisDto, EmployeePostionInfoDto, EmployeeUtilizationByMonthDto, ProfileAllowancesDto, ProfileAllowancesPerYearDto, ProfileBonusPerYearDto, ProfileDataDto, ProfileJobGradeSalaryScalesDto, ProfilePerformanceDto, ProfileSalariesPerYearDto, ProfileSalaryAnalysisResponseDto, ProfileSalaryAndAppraisalDataDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmployeeProfileService {
  apiName = 'Default';

  getAllowancesPerYearByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileAllowancesPerYearDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-allowancesPerYear',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getBonusPerYearByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileBonusPerYearDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-bonusPerYear',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpAllowancesByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileAllowancesDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-EmpAllowances',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpCareerPath = (employeeId: string) =>
    this.restService.request<any, EmpCareerPathDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-EmpCareerPath',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpCertificationByEmployeeId = (employeeId: string) =>
    this.restService.request<any, EmpCertificationTrainingDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-certification',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpEducationByEmployeeId = (employeeId: string) =>
    this.restService.request<any, EmpEducationDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-educations',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpJobHistory = (employeeId: string) =>
    this.restService.request<any, EmpJobHistoryDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-EmpJobHistory',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmpPositionOrgChart = (employeeId: string) =>
    this.restService.request<any, EmpPositionOrgChartDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-EmpPositionOrgChart',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmployeeAttendanceAnalysisData = (employeeId: string, year: string, month: string) =>
    this.restService.request<any, EmployeeAttendanceAnalysisDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/emp-AttendanceAnalysis',
      params: { employeeId, year, month },
    },
    { apiName: this.apiName });

  getEmployeePositionInfo = (employeeId: string) =>
    this.restService.request<any, EmployeePostionInfoDto>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-positionInfo',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getEmployeeUtilizationByMonth = (employeeId: string, year: string) =>
    this.restService.request<any, EmployeeUtilizationByMonthDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/emp-UtilizationByMonth',
      params: { employeeId, year },
    },
    { apiName: this.apiName });

  getJobGradeSalaryScalesByEntityIdAndJobGrade = (entityId: string, jobGrade: string) =>
    this.restService.request<any, ProfileJobGradeSalaryScalesDto>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-jobGradeSalaryScales',
      params: { entityId, jobGrade },
    },
    { apiName: this.apiName });

  getProfileDataByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileDataDto>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-data',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getProfilePerformanceByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfilePerformanceDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-performance',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getProfileSalariesPerYearByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileSalariesPerYearDto[]>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-salariesPerYear',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getProfileSalaryAnalysisByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileSalaryAnalysisResponseDto>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-salaryAnalysis',
      params: { employeeId },
    },
    { apiName: this.apiName });

  getProfileSalaryAndAppraisalDataByEmployeeId = (employeeId: string) =>
    this.restService.request<any, ProfileSalaryAndAppraisalDataDto>({
      method: 'GET',
      url: '/api/intelligence/employee-profile/profile-SalaryAndAppraisal',
      params: { employeeId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
