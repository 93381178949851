import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProfileTabs } from '../profile-content-right/profile-content-right.component';

@Component({
    selector: 'app-compensation-card',
    templateUrl: './compensation-card.component.html',
    styleUrls: ['./compensation-card.component.scss'],
})
export class CompensationCardComponent implements OnInit, AfterViewInit {
    @Input() employeeId;

    tabs = [] as ProfileTabs[];

    @ViewChild('compensation') compensationTemplate;
    @ViewChild('salaryAnalysis') salaryAnalysisTemplate;
    @ViewChild('title') titleTemplate;

    constructor(private localization: LocalizationService) {}
    ngAfterViewInit(): void {
        this.initializeTabs();
    }

    initializeTabs() {
        this.tabs = [
            {
                title: this.localization.instant('OrgChart::Compensation & Benifits'),
                contentTemplate: this.compensationTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: true,
            },
            {
                title: this.localization.instant('OrgChart::Salary Analysis'),
                contentTemplate: this.salaryAnalysisTemplate,
                dataContext: { employeeId: this.employeeId },
                icon: '',
                titleTemplate: this.titleTemplate,
                subMenu: [],
                isActive: false,
            },
        ];
    }
    ngOnInit(): void {}
}
