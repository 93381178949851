import { AuthService, CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { HTTP_ERROR_HANDLER, ThemeSharedModule } from '@abp/ng.theme.shared';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    DEFAULT_CURRENCY_CODE,
    Injector,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { EmployeeProfileModule } from './employee-profile/employee-profile.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LogoutComponent } from './logout/logout.component';
import { ZenThemeModule } from 'zen-package';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { HttpRequestProducCodeInterceptor } from './interceptors/http-request-produc-code-interceptor';
import { EntityNavigationComponent } from './entity-navigation/entity-navigation.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './common-components/header/header.component';
import { ResetPasswordComponent } from './common-components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './common-components/forget-password/forget-password.component';
import { SharedModule } from './shared/shared.module';
import { initializeDefaultCurrencyCode } from './providers/currency.provider';
import { ZenSnackbarService, ZenSnackBarTypes } from './shared/services/zen-snackbar.service';

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule.forRoot({
            environment,
            registerLocaleFn: registerLocale(),
        }),
        ThemeSharedModule.forRoot(),
        ThemeBasicModule.forRoot(),
        PDFExportModule,
        EmployeeProfileModule,
        ChartsModule,
        GridModule,
        TreeViewModule,
        ListViewModule,
        IndicatorsModule,
        DropDownsModule,
        ZenThemeModule,
        HttpClientModule,
        MatSelectModule,
        MatTreeModule,
        MatIconModule,
        SharedModule,
    ],
    declarations: [
        AppComponent,
        LogoutComponent,
        EntityNavigationComponent,
        HeaderComponent,
        ResetPasswordComponent,
        ForgetPasswordComponent,
    ],
    providers: [
        APP_ROUTE_PROVIDER,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestProducCodeInterceptor,
            multi: true,
        },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeAppFactory,
        //     deps: [Injector],
        //     multi: true,
        // },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useFactory: initializeDefaultCurrencyCode,
        },
        { provide: HTTP_ERROR_HANDLER, useValue: handleHttpErrors }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule { }


export function handleHttpErrors(injector: Injector, httpError: HttpErrorResponse) {
    const auth = injector.get(AuthService);
    const snackbar = injector.get(ZenSnackbarService);
    if (httpError.status === 400) {
        snackbar.snackBar({
            message: httpError.error?.error?.message || 'Bad request!',
            type: ZenSnackBarTypes.Error,
        });
        return;
    }

    if (httpError.status === 404) {
        // const contentProjection = injector.get(ContentProjectionService);
        // contentProjection.projectContent(PROJECTION_STRATEGY.AppendComponentToBody(Error404Component));
        return;
    }

    if (httpError.status === 500) {
        snackbar.snackBar({
            message: httpError.error?.error?.message || 'Bad request!',
            type: ZenSnackBarTypes.Error,
        });
        return;
    }

    if (httpError.status === 401) {
        snackbar.snackBar({
            message: httpError.error?.error?.message || 'Unauthorized Access!',
            type: ZenSnackBarTypes.Error,
        });
        setTimeout(() => {
            auth.navigateToLogin();
        }, 500);
        return;
    }

    return; /*throwError(httpError);*/
}