export enum eOrgchartTemplate {
    Default = 'zenithr_default',
    Functional = 'zenithr_functional',
    TwoCriteria = 'zenithr_default_two_criteria',
    SingleCriteria = 'zenithr_default_single_criteria',
    Basic = 'zenithr_basic',
    BasicSingleCriteria = 'zenithr_basic_single_criteria',
    BasicTwoCriteria = 'zenithr_basic_two_criteria',
    Grouping = 'zenithr_grouping',
}
