import { SessionStateService } from '@abp/ng.core';
import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { stringify } from 'querystring';
import { ChildrenAnimation, fadeUpDown } from '../../animations';

@Component({
    selector: 'z-select',
    templateUrl: './zen-select.component.html',
    styleUrls: ['./zen-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ZenSelectComponent,
        },
    ],
    animations: [fadeUpDown, ChildrenAnimation],
})
export class ZenSelectComponent implements ControlValueAccessor {
    showOptions = false;
    openOptions() {
        this.showOptions = !this.showOptions;
    }

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
    ) { }
    @ContentChild('valueTemplate') valueTemplate: TemplateRef<any>;
    @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;
    @ViewChild('trigger') trigger: ElementRef;
    @ViewChild('outerBody') outerBody: ElementRef;
    @ViewChild('body') body: ElementRef;
    open: boolean = false;
    innerOpen: boolean = false;
    searchValue: string;
    selected;
    isDisabled: boolean = false;
    list;
    rtl: boolean = false;
    tempList;
    triggerWidth;
    opposite: boolean = false;
    @Input() invalid = false;
    @Input('trigger') triggeAarea: boolean = true;
    @Input('menuOpen') set menuOpen(condition) {
        if (condition)
            this.initDrop();
    }
    
    @Input() searchText = 'Search...';
    @Input() multiple = false;
    @Input() nullable = false;
    @Input() labelPosition;
    @Input() label;
    @Input() placeholder;
    @Input() key = 'key';
    @Input() color = 'int';
    @Input() language = 'EN';
    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() openChange: EventEmitter<any> = new EventEmitter<any>();
    @Input('options') set ops(options) {
        this.list = options;
        this.tempList = options;
        if (document.body.dir == 'rtl') {
            this.rtl = true;
        }
        console.log(options);

    }

    disable: boolean = false;
    onChange: any = () => { };
    onTouch: any = () => { };

    writeValue(key: any) {
        this.selected = key;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    clear(e) {
        e.stopPropagation();
        this.multiple ? this.selected = [] : this.selected = '';
        this.onChange(this.selected);
        this.selectionChange.emit(this.selected);
    }

    handleSelection(option) {
        console.log(option);

        if (!this.multiple) {
            this.selected = option;
            this.onChange(option);
            this.close();

        }
        else {
            console.log(this.selected);

            if (this.selected.find(s => s == option))
                this.selected.splice(this.selected.indexOf(option), 1);
            else this.selected.push(option);

            this.onChange(this.selected);

        }
        this.selectionChange.emit(this.selected);
    }
    selectionIncluded(key) {
        if (this.multiple)
            return this.selected?.find(s => s == key) ? true : false;
        else return key == this.selected;
    }

    close() {
        this.innerOpen = false;
        setTimeout(() => {
            this.open = false;
            document.body.removeChild(this.outerBody.nativeElement);
            this.openChange.emit(false);
        }, 300);
    }


    searchList(e, array) {
        this.tempList = this.list.filter(s => JSON.stringify(s).toLowerCase().includes(e.toLowerCase()));
    }

    logData(e) {
        console.log(e);

    }
    reverse = false;
    mobileView = false;
    oppositeRight: boolean = false;
    oppositeLeft: boolean = false;
    initDrop(e?) {
        this.reverse = false;
        this.mobileView = false;
        this.oppositeRight = false;
        this.oppositeLeft = false;
        this.open = true;
        this.innerOpen = true;

        let xValue = this.trigger.nativeElement.getBoundingClientRect().left;
        let yValue = this.trigger.nativeElement.getBoundingClientRect().top;
        let triggerHeight = this.trigger.nativeElement.getBoundingClientRect().height;
        this.triggerWidth = this.trigger.nativeElement.getBoundingClientRect().width;
        console.log(this.trigger.nativeElement.getBoundingClientRect());

        this.cdr.detectChanges();

        document.body.appendChild(this.outerBody.nativeElement);
        document.body.clientWidth < 768 ? this.mobileView = true : this.mobileView = false;
        document.body.dir == 'rtl' ? this.rtl = true : this.rtl = false;
        yValue + 50 > window.innerHeight / 2 ? this.reverse = true : this.reverse = false;

        console.log(this.reverse);

        if (!this.mobileView)
            if (this.rtl) {
                if (xValue < 400)
                    this.oppositeLeft = true;

                this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue + this.triggerWidth) + 'px, ' + (yValue + triggerHeight) + 'px)')
            }
            else {
                if (xValue > document.body.clientWidth - 400)
                    this.oppositeRight = true;

                this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue) + 'px, ' + (yValue + triggerHeight) + 'px)');
            }
    }
    getValue(value) {
        return this.list.find(s => s[this.key] == value);
    }


}
function s(s: any) {
    throw new Error('Function not implemented.');
}

